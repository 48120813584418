import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {fetchProject, fetchProjectCreate, fetchProjectUpdate} from "../../api/ApiProject";
import useAxios from "../../api/useAxios";

const ProjectsForm = ({name, type_project, _type, defaultName, uri}) => {

    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const { id } = useParams();

    const [messages, setMessages] = React.useState({message: '', status: ''});

    const handleDefaultNameClient = () => {
        if(_type === "create") {
            if(type_project === 1) {
                return defaultName;
            }
        }

        return '';
    }

    const [inputs, setInputs] = React.useState({
        display_name: '',
        client_name: handleDefaultNameClient(),
    });

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    const handleCreateProject = async (e) => {
        e.preventDefault();

        const response = await fetchProjectCreate(axiosInstance, type_project, inputs);

        if(response.status === 'success') {
            navigate(`/projects/${uri}`, {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const handleUpdateProject = async (e) => {
        e.preventDefault();

        const response = await fetchProjectUpdate(axiosInstance, id, inputs);

        if(response.status === 'success') {
            navigate(`/projects/${uri}`, {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger'
            })
        }
    }

    useEffect(() => {
        if(_type === 'edit') {
            getProject(id);
        }

        async function getProject(projectId) {
            const response = await fetchProject(axiosInstance, projectId);

            if(response.status === 'success') {
                setInputs({
                    display_name: response.project.display_name,
                    client_name: response.project.client_name,
                });
            }
            else {
                navigate(`/projects/${uri}`, {state: {message: response.message, status: 'danger'}});
            }
        }
    }, [_type, axiosInstance]);

    if(_type === 'edit' && inputs.display_name === "") {
        return "";
    }


    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{_type === 'create' ? `Dodaj projekt ${name}` : `Edytuj projekt ${name}`} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/projects/external-orders'}>Projekty</Link></li>
                        <li className="breadcrumb-item active"
                            aria-current="page">{_type === 'create' ? `Dodaj projekt ${name}` : `Edytuj projekt ${name}`}
                        </li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages &&
                                <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                            <form className="forms-sample" onSubmit={_type === 'create' ? handleCreateProject : handleUpdateProject}>
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_name">Nazwa projektu</label>
                                    <input type="text" className="form-control" id="InputDisplay_name"
                                           placeholder="Nazwa projektu"
                                           value={inputs.display_name}
                                           onChange={(e) => handleChangeInput('display_name', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputClient_name">Klient</label>

                                    <input type="text" className="form-control" id="InputClient_name" name="client_name"
                                           placeholder="Klient"
                                           value={inputs.client_name}
                                           onChange={(e) => handleChangeInput('client_name', e.target.value)}

                                    />
                                </div>

                                <br/><br/>
                                <button type="submit"
                                        className="btn btn-primary mr-2">{_type === 'create' ? 'Utwórz' : 'Edytuj'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectsForm;