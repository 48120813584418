export const fetchProjectsList = async (axiosInstance, type_project) => {
    try {
        const response = await axiosInstance.get(`/projects/${type_project}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProject = async (axiosInstance, id_project) => {
    try {
        const response = await axiosInstance.get(`/project/${id_project}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectCreate = async (axiosInstance, type_project, data) => {
    try {
        const response = await axiosInstance.post(`/project/create/${type_project}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectUpdate = async (axiosInstance, id_project, data) => {
    try {
        const response = await axiosInstance.put(`/project/update/${id_project}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDelete = async (axiosInstance, id_project) => {
    try {
        const response = await axiosInstance.delete(`/project/delete/${id_project}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetail = async (axiosInstance, id_project) => {
    try {
        const response = await axiosInstance.get(`/projects/detail/${id_project}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetailCreatePosition = async (axiosInstance, id_project, data) => {
    try {
        const response = await axiosInstance.post(`/projects/detail/create-position/${id_project}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetailCreatePositionPart = async (axiosInstance, id_position, data) => {
    try {
        const response = await axiosInstance.post(`/projects/detail/create-position-part/${id_position}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchGetPositionPart = async (axiosInstance, id_position_part) => {
    try {
        const response = await axiosInstance.get(`/projects/detail/get-position-part/${id_position_part}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetailDeletePosition = async (axiosInstance, id_position) => {
    try {
        const response = await axiosInstance.delete(`/projects/detail/delete/position/${id_position}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetailDeletePart = async (axiosInstance, id_part) => {
    try {
        const response = await axiosInstance.delete(`/projects/detail/delete/position-part/${id_part}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectPositionPartImportExcel = async (axiosInstance, id_position, formData) => {
    try {
        const response = await axiosInstance.post(`/projects/detail/position-part/import-excel/${id_position}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectDetailPositionPartUpdateStatus = async (axiosInstance, id_position_part, status) => {
    try {
        const response = await axiosInstance.put(`/projects/detail/position-part/update-status/${id_position_part}`, {status: status});
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectCNCToBeDone = async (axiosInstance, status) => {
    try {
        const response = await axiosInstance.get(`/cnc-machining/to-be-done/${status}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectCNCPerMachineForNoStatus = async (axiosInstance, status_cnc, id_machine) => {
    try {
        const response = await axiosInstance.get(`/cnc-machining/per-machine/${status_cnc}/${id_machine}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchProjectPositionPartUpdate = async (axiosInstance, id_position_part, data) => {
    try {
        const response = await axiosInstance.put(`/project-position-part/update/${id_position_part}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchFinishPositionPartCNC = async (axiosInstance, status, status_cnc) => {
    try {
        const response = await axiosInstance.get(`/cnc-machining/finish/${status}/${status_cnc}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}