import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiLibrary, mdiPlus} from "@mdi/js";
import useAxios from "../../api/useAxios";
import {fetchRoleDelete, fetchRoleList} from "../../api/ApiRole";

function Roles() {
    const axiosInstance = useAxios();
    const location = useLocation();
    const messageLocation = location.state?.message;
    const statusLocation = location.state?.status;

    const hasRunGetRoles = useRef(false);
    const [roles, setRoles] = useState([]);
    const [refreshRole, setRefreshRole] = useState(false);
    const [messages, setMessage] = useState({message: messageLocation ? messageLocation : '', status: statusLocation ? statusLocation : ''});

    const deleteRole = async (idRole) => {
        const response = await fetchRoleDelete(axiosInstance, idRole);

        if(response.status === 'success') {
            setRefreshRole(true);
            setMessage({message: response.message, status: 'success'});
        }
    }

    useEffect(() => {
        if(refreshRole || (!roles.length && !hasRunGetRoles.current)) {
            rolesList();
        }

        async function rolesList() {
            setRefreshRole(true)
            const response = await fetchRoleList(axiosInstance);

            if(response.status === 'success') {
                setRoles(response.roles);
            }

            setRefreshRole(false);
            hasRunGetRoles.current = true;
        }
    }, [refreshRole, axiosInstance, roles, hasRunGetRoles]);

    return (

        <>
            <div className="page-header">
                <h3 className="page-title"> Lista ról </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Role</li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages.message && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}

                            <div className="float-right">
                                <Link to={'/roles/create'} type="button"
                                      className="btn btn-info btn-icon-text">
                                    <Icon path={mdiPlus} size={0.7} /> Dodaj rolę
                                </Link>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th style={{width: "5%"}}>Lp.</th>
                                        <th style={{width: "31.6%"}}>Wyświetlana nazwa</th>
                                        <th style={{width: "31.6%"}}>Data utworzenia</th>
                                        <th style={{width: "31.6%"}}>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {refreshRole && (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {roles.map((role, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <label
                                                    className={`badge badge-${role.bg_color}`}>{role.display_name}</label>
                                            </td>
                                            <td>{role.created_at}</td>
                                            <td>
                                                {i > 1 &&
                                                    <div style={{display: 'flex', gap: '7px'}}>
                                                        <Link to={`/roles/edit/${role.id}`} type="button"
                                                              className="btn btn-primary btn-fw">Edytuj
                                                        </Link>
                                                        <button type="button" onClick={() => deleteRole(role.id)}
                                                                className="btn btn-danger btn-fw">Usuń
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Roles;