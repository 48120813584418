export const sortProjectPositionParts = (data, isObject = false) => {

   if(isObject) {
        return {
            ...data,
            project_positions: data.project_positions.map(position => ({
                ...position,
                project_position_part: position.project_position_part.sort((a, b) => {
                    const extractNumber = (str) => {
                        const parts = str.split('_');
                        const number = parseInt(parts[0], 10);
                        return isNaN(number) ? Infinity : number; // Infinity dla nie-numeric
                    };

                    const numA = extractNumber(a.display_number);
                    const numB = extractNumber(b.display_number);

                    return numA - numB;
                })
            }))
        };
    }
   else {
        return data.map(project => ({
            ...project,
            project_positions: project.project_positions.map(position => ({
                ...position,
                project_position_part: position.project_position_part.sort((a, b) => {
                    const extractNumber = (str) => {
                        const parts = str.split('_');
                        const number = parseInt(parts[0], 10);
                        return isNaN(number) ? Infinity : number; // Infinity dla nie-numeric
                    };

                    const numA = extractNumber(a.display_number);
                    const numB = extractNumber(b.display_number);

                    return numA - numB;
                })
            }))
        }));
   }
};

export const sortToBeDoneProject = (data) => {
    return data.map(project => ({
        ...project,
        position_parts: project.position_parts.sort((a, b) => {
            const extractNumber = (str) => {
                const parts = str.split('_');
                const number = parseInt(parts[0], 10);
                return isNaN(number) ? Infinity : number; // Infinity dla nie-numeric
            };

            const numA = extractNumber(a.display_number);
            const numB = extractNumber(b.display_number);

            return numA - numB;
        })
    }))
}

export const findLargestNumber = (data) => {
    let largestNumber = -Infinity;

    data.forEach(project => {
        project.project_positions.forEach(position => {
            position.project_position_part.forEach(part => {
                const extractNumber = (str) => {
                    let parts = str.split('_');
                    console.log(parts);
                    if(parts.length === 0) {
                        parts[0] = str;
                    }
                    const number = parseInt(parts[0], 10);
                    return isNaN(number) ? -Infinity : number; // -Infinity dla nie-numeric
                };

                const num = extractNumber(part.display_number);
                if (num > largestNumber) {
                    largestNumber = num;
                }
            });
        });
    });

    return largestNumber === -Infinity ? "" : largestNumber;
};