import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import useAxios from "../../../api/useAxios";
import {statusColor, typesStatus} from "../../../helpers/Status";
import {map} from "react-bootstrap/ElementChildren";
import {fetchCreateStatus, fetchStatus, fetchUpdateStatus} from "../../../api/ApiStatus";

const StatusForm = ({_type, setRefreshSM}) => {
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const {id} = useParams();
    const [messages, setMessages] = React.useState({message: '', status: ''});

    const [kinds, setKinds] = React.useState([]);

    const [inputs, setInputs] = useState({
        type_status: typesStatus[0].id,
        display_name: '',
        color: statusColor[0].id,
        kind_status: typesStatus[0].kind[0].id,
    })

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    const handleTypeChange = (e) => {
        const selectedType = parseInt(e.target.value);
        handleChangeInput('type_status', selectedType);

        const selectedTypeObj = typesStatus.find(type => type.id === selectedType);
        if(selectedTypeObj) {
            handleChangeInput('kind_status', selectedTypeObj.kind[0].id);
            setKinds(selectedTypeObj.kind);
        }
        else {
            handleChangeInput('kind_status', typesStatus[0].kind[0].id);
            setKinds(typesStatus[0].kind);
        }
    }

    const handleCreateStatus = async (e) => {
        e.preventDefault();

        const response = await fetchCreateStatus(axiosInstance, inputs);

        if(response.status === 'success') {
            setRefreshSM(true);
            navigate('/configure-status', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const handleUpdateStatus = async (e) => {
        e.preventDefault();

        const response = await fetchUpdateStatus(axiosInstance, id, inputs);

        if(response.status === 'success') {
            setRefreshSM(true);
            navigate('/configure-status', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    useEffect(() => {
        if(_type === 'edit') {
            getStatus(id);
        }

        async function getStatus(statusId) {
            const response = await fetchStatus(axiosInstance, statusId);

            if(response.status === 'success') {
                const selectedTypeObj = typesStatus.find(type => type.id === parseInt(response.data.type_status));
                if(selectedTypeObj) {
                    handleChangeInput('kind_status', selectedTypeObj.kind[0].id);
                    setKinds(selectedTypeObj.kind);
                }

                setInputs({
                    type_status: response.data.type_status,
                    display_name: response.data.display_name,
                    color: response.data.color,
                    kind_status: response.data.kind_status,
                });
            }
            else {
                navigate('/configure-status', {state: {message: response.message, status: 'danger'}});
            }
        }
    }, [_type, axiosInstance, id, navigate]);


    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{_type === 'create' ? 'Dodaj status' : 'Edytuj rolę'} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/configure-status'}>Konfigurator statusów</Link></li>
                        <li className="breadcrumb-item active"
                            aria-current="page">{_type === 'create' ? 'Dodaj status' : 'Edytuj status'}
                        </li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages &&
                                <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                            <form className="forms-sample" onSubmit={_type === 'create' ? handleCreateStatus : handleUpdateStatus}>
                                {_type === 'create' && <div className="form-group">
                                    <label htmlFor="Input_Type">Typ statusu</label>
                                    <select className="form-control" id="Input_Type"
                                            
                                            defaultValue={inputs.type_status}
                                            onChange={(e) => handleTypeChange(e)}
                                    >
                                        {typesStatus.map((type) => (
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>}
                                <div className="form-group">
                                    <label htmlFor="Input_Kind">Rodzaj statusu</label>
                                    <select className="form-control" id="Input_Kind"
                                            
                                            defaultValue={inputs.kind_status}
                                            onChange={(e) => handleChangeInput('kind_status', e.target.value)}
                                    >
                                        {kinds.length === 0 ?
                                            (typesStatus[0].kind.map((item, index) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )))
                                            :
                                            (kinds.map((kind) => (
                                                <option key={kind.id} value={kind.id}>{kind.name}</option>
                                            )))
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_name">Wyświetlana nazwa</label>
                                    <input type="text" className="form-control" id="InputDisplay_name"
                                           value={inputs.display_name}
                                           onChange={(e) => handleChangeInput('display_name', e.target.value)}
                                           placeholder="Wyświetlana nazwa"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputBg_Color">Kolor statusu</label>
                                    <select className="form-control" id="InputBg_Color" name="color"
                                            
                                            defaultValue={inputs.color}
                                            onChange={(e) => handleChangeInput('color', e.target.value)}
                                    >
                                        {statusColor.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <br/><br/>
                                <button type="submit"
                                        className="btn btn-primary mr-2">{_type === 'create' ? 'Utwórz' : 'Edytuj'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatusForm