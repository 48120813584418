import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

const dValue = {
    color: 'primary',
    display_name: 'Brak danych'
}

const CustomSelect = ({
                          title = 'Wybierz Status',
                          defaultValue = dValue,
                          isOutline = false,
                          customClass = '',
                          clickable = true,
                          children,
                          onSelect
                      }
) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const [position, setPosition] = useState({ top: 0, left: 0 }); // Dodajemy stan na pozycję
    const selectRef = useRef(null);

    const handleOpen = () => {
        setIsOpen(!isOpen);
        if (selectRef.current) {
            const rect = selectRef.current.getBoundingClientRect();
            setPosition({ top: rect.bottom + window.scrollY, left: rect.left });
        }
    }

    const handleClickOutside = (event) => {
        if (selectRef.current &&
            !selectRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSelect = (option) => {
        setSelectedValue(option);
        setIsOpen(false);
        if (onSelect) onSelect(option);
    }

    return (
        <div className="my-select" ref={selectRef} title={title}>
            <div
                className={`cursor-pointer badge badge${isOutline ? '-outline' : ''}-${defaultValue.color} ${customClass}`}
                onClick={handleOpen}
            >
                {defaultValue.display_name}
            </div>

            {clickable && createPortal(<div className={`select-box ${isOpen ? 'active' : ''}`}
                 style={{ position: 'absolute', top: `${position.top}px`, left: `${position.left}px` }}
            >
                <p>{title}</p>
                <div className={"select-box-list"}>
                    {React.Children.map(children, (child) =>
                        React.cloneElement(child, {
                            onClick: () => handleSelect(child.props.option),
                        })
                    )}
                </div>
            </div>, document.body)}

        </div>
    )
}

export default CustomSelect;