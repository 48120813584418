import React, {useEffect} from "react";
import {Doughnut} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

import useAxios from "../../api/useAxios";
import {complicatedStatus, TYPE_STATUS_CNC} from "../../helpers/Status";
import {fetchProjectCNCPerMachineForNoStatus} from "../../api/ApiProject";
import DashboardModalTableProjectComplicated from "../../components/DashboardModalTableProjectComplicated";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({statuses, machines}) => {
    const axiosInstance = useAxios();

    const [isOpenAccordion, setIsOpenAccordion] = React.useState([]);
    const isOpenAccordionRef = React.useRef(isOpenAccordion);


    const handleToggleAccordion = (index, id) => {
        let newIsOpenAccordion = [...isOpenAccordionRef.current];
        newIsOpenAccordion[index][id] = !newIsOpenAccordion[index][id];
        isOpenAccordionRef.current = newIsOpenAccordion;
        setIsOpenAccordion(newIsOpenAccordion);
    }

    const [showModalTableProjectComplicated, setShowModalTableProjectComplicated] = React.useState(false);
    const [modalTableProjectComplicatedData, setModalTableProjectComplicatedData] = React.useState(null);

    const hasRunGetData = React.useRef(false);
    const [transactionHistoryData, setTransactionHistoryData] = React.useState(null);
    const [showDoughnut, setShowDoughnut] = React.useState(false);

    useEffect(() => {
        if (!hasRunGetData.current) {
            getDataFromMachine();
        }

        async function getDataFromMachine() {
            if (statuses.length > 0 && machines.length > 0) {
                const response_cnc = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
                const status_cnc = response_cnc.find(s => s.kind_status === 'status_end');

                let historyData = [];
                let isOpenAccordionArr = [];
                for (let i = 0; i < machines.length; i++) {
                    historyData.push({
                        labels: ["Mały", "Średni", "Duży"],
                        datasets: [{
                            data: [],
                            backgroundColor: [
                                "#00d25b", "#ffab00", "#fc424a"
                            ],
                            machineIndex: i
                        }]
                    });
                    isOpenAccordionArr.push([false, false, false]);
                }

                setIsOpenAccordion(isOpenAccordionArr);
                isOpenAccordionRef.current = isOpenAccordionArr;

                for (const [index, machine] of machines.entries()) {
                    const response = await fetchProjectCNCPerMachineForNoStatus(axiosInstance, status_cnc.id, machine.id);
                    let c_1 = 0;
                    let c_2 = 0;
                    let c_3 = 0;

                    if (response.status === 'success') {
                        response.projects.forEach((project, i) => {
                            const complicated = parseInt(response.projects[i].position_parts.complicated);
                            if (complicated === complicatedStatus[0].id) {
                                c_1++;
                            } else if (complicated === complicatedStatus[1].id) {
                                c_2++;
                            } else if (complicated === complicatedStatus[2].id) {
                                c_3++;
                            } else {
                                c_1++;
                            }

                            historyData[index].datasets[0].data = [c_1, c_2, c_3]
                        });
                    }


                    if (historyData[index].datasets[0].data.length === 0) {
                        historyData[index].datasets[0].data = [0, 0, 0];
                    }
                }

                setTransactionHistoryData(historyData);
                setShowDoughnut(true);

                hasRunGetData.current = true;
            }


        }

    }, [hasRunGetData])

    const [transactionHistoryOptions, setTransactionHistoryOptions] = React.useState({
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem) {
                        const dataIndex = tooltipItem[0].dataIndex;
                        const data = tooltipItem[0].dataset.data[dataIndex]
                        return `ST: ${data}`;
                    },
                    label: function(tooltipItem, data) {
                        // const labelName = tooltipItem.label;
                        // const machineIndex = tooltipItem.dataset.machineIndex;
                        // let id = 0;
                        //
                        // if(labelName === "Mały") {
                        //     id = 0;
                        // }
                        // if(labelName === "Średni") {
                        //     id = 1;
                        // }
                        // if(labelName === "Duży") {
                        //     id = 2;
                        // }
                        //
                        // handleToggleAccordion(machineIndex, id);
                        return "";
                    },
                }
            },
        }
    });


    const handleSumDoughnut = (tHData) => {
        let sum = 0

        sum = tHData.datasets[0].data[0] +
            tHData.datasets[0].data[1] +
            tHData.datasets[0].data[2]


        if (sum === 0) {
            return (
                <>
                    <h5 className="font-weight-normal text-white text-center mb-2">
                        Brak danych
                    </h5>
                </>
            )
        }

        return (
            <>
                <h5 className="font-weight-normal text-white text-center mb-2">
                    {sum}
                </h5>
                <p className="text-small text-muted text-center mb-0">SUMA</p>
            </>
        )

    }

    const handleShowModalTableProjectComplicated = (data) => {
        setModalTableProjectComplicatedData(data);
        setShowModalTableProjectComplicated(true);
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title"> Dashboard </h3>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div className="row">
                        {machines.map((machine, index) => (
                            <div key={`machine_${index}_doughunt`} className="col-md-3 grid-margin stretch-card">
                                <div className="card">
                                    {showDoughnut ?
                                        <div className="card-body">
                                            <h4 className="card-title">{machine.display_name}</h4>
                                            <div
                                                className="aligner-wrapper d-flex align-items-center flex-column position-relative">
                                                <div style={{width: '150px', height: '150px'}}>
                                                    <Doughnut
                                                        data={transactionHistoryData[index]}
                                                        options={transactionHistoryOptions}
                                                    />
                                                </div>
                                                <div className="position-absolute center-content"
                                                     style={{top: '50%', transform: 'translateY(-50%)'}}>
                                                    {handleSumDoughnut(transactionHistoryData[index])}
                                                </div>
                                            </div>
                                            <div
                                                className={`bg-gray-dark border-white d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3 cursor-pointer ${isOpenAccordionRef.current[index][0] ? 'active' : ''}`}
                                                onClick={() => handleShowModalTableProjectComplicated({
                                                    machine_id: machine.id,
                                                    complicated_id: 1,
                                                    machine_name: machine.display_name
                                                })}
                                            >
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Stopień skomplikowania: Mały</h6>
                                                </div>
                                                <div
                                                    className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="font-weight-bold mb-0">{transactionHistoryData[index].datasets[0].data[0]}</h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`bg-gray-dark border-white d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3 cursor-pointer ${isOpenAccordionRef.current[index][1] ? 'active' : ''}`}
                                                onClick={() => handleShowModalTableProjectComplicated({
                                                    machine_id: machine.id,
                                                    complicated_id: 2,
                                                    machine_name: machine.display_name
                                                })}
                                            >
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Stopień skomplikowania: Średni</h6>
                                                </div>
                                                <div
                                                    className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0 ">
                                                    <h6 className="font-weight-bold mb-0">{transactionHistoryData[index].datasets[0].data[1]}</h6>
                                                </div>
                                            </div>
                                            <div
                                                className={`bg-gray-dark border-white d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3 cursor-pointer ${isOpenAccordionRef.current[index][2] ? 'active' : ''}`}
                                                onClick={() => handleShowModalTableProjectComplicated({
                                                    machine_id: machine.id,
                                                    complicated_id: 3,
                                                    machine_name: machine.display_name
                                                })}
                                            >
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Stopień skomplikowania: Wysoki</h6>
                                                </div>
                                                <div
                                                    className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="font-weight-bold mb-0">{transactionHistoryData[index].datasets[0].data[2]}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={"card-body d-flex align-items-center justify-content-center"}
                                             style={{height: '200px'}}>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <DashboardModalTableProjectComplicated
                show={showModalTableProjectComplicated}
                setShow={setShowModalTableProjectComplicated}
                data={modalTableProjectComplicatedData}
                statuses={statuses}
            />
        </>
    );
}

export default Dashboard;
