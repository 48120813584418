import React from 'react';
import {mdiAttachment} from "@mdi/js";
import Icon from "@mdi/react";

const Attachment = ({hyperlink}) => {
    const [showTooltip, setShowTooltip] = React.useState(false);

    const copyHyperLink = () => {
        navigator.clipboard.writeText(hyperlink).then(() => {
            setShowTooltip(true);
        });

        setTimeout(() => {
            setShowTooltip(false);
        }, 1500);
    }

    if(!hyperlink) {
        return '-';
    }

    return (
        <div className={'position-relative'}>
            {showTooltip && (
                <div className="tooltip">
                    Skopiowano!
                </div>
            )}
            <button type="button"
                    className="btn btn-inverse-success btn-icon"
                    title={"Kliknij aby skopiować załącznik"}
                    onClick={copyHyperLink}
            >
                <Icon path={mdiAttachment} size={0.7} />
            </button>
        </div>
    )
}

export default Attachment;