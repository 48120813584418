export const statusColor = [
    { id: 'primary', name: 'Niebieski' },
    { id: 'danger', name: 'Czerwony' },
    { id: 'info', name: 'Fioletowy' },
    { id: 'success', name: 'Zielony' },
    { id: 'warning', name: 'Żółty' },
    { id: 'light', name: 'Biały' },
    { id: 'dark', name: 'Czarny' },
];

export const complicatedStatus = [
    {id: 1, display_name: 'Mały', color: 'success' },
    {id: 2, display_name: 'Średni', color: 'warning' },
    {id: 3, display_name: 'Wysoki', color: 'danger' },
]

export const TYPE_STATUS_PROJECT_PART_ID = 2;
export const TYPE_STATUS_CNC = 3;

export const typesStatus = [
    {
        id: TYPE_STATUS_PROJECT_PART_ID,
        name: 'Projekty - Części',
        kind: [
            {
                id: 'status_normal',
                name: 'Status informacyjny',
            },
            {
                id: 'status_end',
                name: 'Status zakończenia',
            },
            {
                id: 'status_move_cnc',
                name: 'Status przeniesienia do obróbki CNC'
            }
        ]
    },
    {
        id: TYPE_STATUS_CNC,
        name: 'Obróbka CNC',
        kind: [
            {
                id: 'status_normal',
                name: 'Status informacyjny',
            },
            {
                id: 'status_start',
                name: 'Status startowy',
            },
            {
                id: 'status_end',
                name: 'Status zakończenia',
            },
        ]
    }
]

export const YesOrNo = [
    {id: 0, display_name: 'Nie', color: 'danger'},
    {id: 1, display_name: 'Tak', color: 'success'},
]