import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {
    fetchGetPositionPart,
    fetchProjectDetailCreatePositionPart,
    fetchProjectPositionPartUpdate
} from "../../api/ApiProject";
import useAxios from "../../api/useAxios";
import {fetchGetStatusForType} from "../../api/ApiStatus";
import {TYPE_STATUS_PROJECT_PART_ID} from "../../helpers/Status";
import {mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import {handleIsValid} from "../../helpers/globalHelpers";

const ProjectDetailModalAddPart = ({show, setShow, dataModal, statuses}) => {
    const axiosInstance = useAxios();

    const [messages, setMessages] = useState({
        message: '', status: ''
    });

    const [isGit, setIsGit] = useState(false);

    const [inputs, setInputs] = useState({
        position_part_display_number: '',
        position_part_display_name: '',
        position_part_description: '',
        position_part_quantity: '',
        position_part_name_material: '',
        position_part_dimensions: '',
        position_part_material_order: 'Zamówiono',
        position_part_temper: '1',
        position_part_azotize: '1',
        position_part_hyperlink: '',
        position_part_status: 0
    });

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    useEffect(() => {
        const status = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);

        if(dataModal.isEdit === true) {
            getEditPart(dataModal.idPart);
        }
        else {
            setInputs({
                position_part_display_number: '',
                position_part_display_name: '',
                position_part_description: '',
                position_part_quantity: '',
                position_part_name_material: '',
                position_part_dimensions: '',
                position_part_material_order: 'Zamówiono',
                position_part_temper: '0',
                position_part_azotize: '0',
                position_part_hyperlink: '',
                position_part_status: parseInt(status[0].id)
            });

            setIsGit(true);
        }

        async function getEditPart(idPart) {
            const response = await fetchGetPositionPart(axiosInstance, idPart);

            if(response.status === 'success') {
                setInputs({
                    position_part_display_number: response.position_part.display_number,
                    position_part_display_name: response.position_part.display_name,
                    position_part_description: response.position_part.description,
                    position_part_quantity: response.position_part.quantity,
                    position_part_name_material: response.position_part.name_material,
                    position_part_dimensions: response.position_part.dimensions,
                    position_part_material_order: response.position_part.material_order,
                    position_part_temper: response.position_part.temper,
                    position_part_azotize: response.position_part.azotize,
                    position_part_hyperlink: response.position_part.hyperlink,
                    position_part_status: response.position_part.status
                });

                setIsGit(true);
            }
        }

        setMessages({
            message: '', status: ''
        })

    }, [axiosInstance, show]);


    const handleSave = async () => {
        const response = await fetchProjectDetailCreatePositionPart(axiosInstance, dataModal.positionId, inputs);

        if(response.status === 'success') {
            setShow(false);
            setInputs({
                position_part_display_number: '',
                position_part_display_name: '',
                position_part_description: '',
                position_part_quantity: '',
                position_part_name_material: '',
                position_part_dimensions: '',
                position_part_material_order: 'Zamówiono',
                position_part_temper: '0',
                position_part_azotize: '0',
                position_part_hyperlink: '',
                position_part_status: 0
            });
            setMessages({
                message: '', status: ''
            })
        }
        else {
            setMessages({
                message: response.message, status: 'danger'
            })
        }
    }

    const handleEdit = async () => {
        const response = await fetchProjectPositionPartUpdate(axiosInstance, dataModal.idPart, {
            display_number: inputs.position_part_display_number,
            display_name: inputs.position_part_display_name,
            description: inputs.position_part_description,
            quantity: inputs.position_part_quantity,
            name_material: inputs.position_part_name_material,
            dimensions: inputs.position_part_dimensions,
            material_order: inputs.position_part_material_order,
            temper: inputs.position_part_temper,
            azotize: inputs.position_part_azotize,
            hyperlink: inputs.position_part_hyperlink,
            status: inputs.position_part_status
        });

        if(response.status === 'success') {
            setShow(false);
            setInputs({
                position_part_display_number: '',
                position_part_display_name: '',
                position_part_description: '',
                position_part_quantity: '',
                position_part_name_material: '',
                position_part_dimensions: '',
                position_part_material_order: 'Zamówiono',
                position_part_temper: '0',
                position_part_azotize: '0',
                position_part_hyperlink: '',
                position_part_status: 0
            });
            setMessages({
                message: '', status: ''
            })
        }
        else {
            setMessages({
                message: response.message, status: 'danger'
            })
        }
    }

    const hide = () => {
        setShow(false);
        setIsGit(false);
    }


    if(!isGit) {
        return "";
    }

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header>
                <Modal.Title>{dataModal.title}</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                {messages.message && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                <form className="forms-sample">
                    <div className="form-group">
                        <label htmlFor="example">Numer części</label>
                        <input type="text" className={`form-control ${handleIsValid(inputs.position_part_display_number)}`} id="PositionPartDisplayNumber"
                               value={inputs.position_part_display_number}
                               onChange={(e) => handleChangeInput('position_part_display_number', e.target.value)}
                               placeholder="Numer częsci"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Nazwa części</label>
                        <input type="text" className={`form-control ${handleIsValid(inputs.position_part_display_name)}`} id="PositionPartDisplayName"
                               value={inputs.position_part_display_name}
                               onChange={(e) => handleChangeInput('position_part_display_name', e.target.value)}
                               placeholder="Nazwa części"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Opis</label>
                        <textarea className="form-control" placeholder="Opis..."
                                  value={inputs.position_part_description}
                                  onChange={(e) => handleChangeInput('position_part_description', e.target.value)}
                                  id="PositionPartDescription"></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Ilość</label>
                        <input type="number" className={`form-control ${handleIsValid(inputs.position_part_quantity)}`} id="PositionPartQuantity"
                               value={inputs.position_part_quantity}
                               onChange={(e) => handleChangeInput('position_part_quantity', e.target.value)}
                               placeholder="Ilość"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Nazwa materiału</label>
                        <input type="text" className={`form-control ${handleIsValid(inputs.position_part_name_material)}`} id="PositionPartNameMaterial"
                               value={inputs.position_part_name_material}
                               onChange={(e) => handleChangeInput('position_part_name_material', e.target.value)}
                               placeholder="Nazwa materiału"/>

                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Gabaryt</label>
                        <input type="text" className={`form-control ${handleIsValid(inputs.position_part_dimensions)}`} id="PositionPartDimensions"
                               value={inputs.position_part_dimensions}
                               onChange={(e) => handleChangeInput('position_part_dimensions', e.target.value)}
                               placeholder="Gabaryt"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Zamówienie Materiału</label>
                        <select className="form-control" id="PositionPartMaterialOrder"
                                defaultValue={inputs.position_part_material_order}
                                onChange={(e) => handleChangeInput('position_part_material_order', e.target.value)}>
                            <option value="Zamówiono">Zamówiono</option>
                            <option value="Na stanie">Na stanie</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Hartować</label>
                        <select className="form-control" id="PositionPartTemper"
                                defaultValue={inputs.position_part_temper}
                                onChange={(e) => handleChangeInput('position_part_temper', e.target.value)}>
                            <option value="1">Tak</option>
                            <option value="0">Nie</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Azotować</label>
                        <select className="form-control" id="PositionPartAzotize"
                                defaultValue={inputs.position_part_azotize}
                                onChange={(e) => handleChangeInput('position_part_azotize', e.target.value)}>
                            <option value="1">Tak</option>
                            <option value="0">Nie</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Status</label>
                        <select className="form-control"
                                value={parseInt(inputs.position_part_status)}
                                onChange={(e) => handleChangeInput('position_part_status', e.target.value)}>
                            {statuses
                                .filter((s) => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID)
                                .map((status) => (
                                <option key={status.id} value={status.id}>{status.display_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Załącznik</label>
                        <input type="text" className="form-control" id="PositionPartHyperlink"
                               value={inputs.position_part_hyperlink}
                               onChange={(e) => handleChangeInput('position_part_hyperlink', e.target.value)}
                               placeholder="Hiperłącze"/>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Zamknij
                </Button>
                {dataModal.isEdit === true && <Button variant="primary" onClick={handleEdit}>
                    Edytuj
                </Button>}
                {dataModal.isEdit === false && <Button variant="primary" onClick={handleSave}>
                    Zapisz
                </Button>}

            </Modal.Footer>
        </Modal>
    )
}

export default ProjectDetailModalAddPart