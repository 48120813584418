import React, {useEffect, useRef, useState} from 'react';
import useAxios from "../../../api/useAxios";
import {Link, useLocation} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiPlus} from "@mdi/js";
import {fetchConfigureList, fetchConfigureRemove} from "../../../api/ApiConfigureOther";

const Configure = ({name, configure_type, table_name, uri, btn_add}) => {
    const axiosInstance = useAxios();
    const location = useLocation();
    const messageLocation = location.state?.message;
    const statusLocation = location.state?.status;

    const [messages, setMessage] = useState({
        message: messageLocation ? messageLocation : '',
        status: statusLocation ? statusLocation : ''
    });

    const hasRunGetOther = useRef(false);
    const [others, setOthers] = useState([]);
    const [refreshOther, setRefreshOther] = useState(false);

    const handleRemove = async (_id) => {
        let _type = 1;
        if(configure_type === 'operator') _type = 2;
        else if(configure_type === 'machine') _type = 3;

        const response = await fetchConfigureRemove(axiosInstance, _type, _id);

        if(response.status === 'success') {
            setMessage({
                message: response.message,
                status: 'success',
            });
            setRefreshOther(true);
        }
        else {
            setMessage({
                message: response.message,
                status: 'success'
            });
        }
    }

    useEffect(() => {
        setRefreshOther(true);
        setOthers([]);
    }, [configure_type])

    useEffect(() => {
        if(refreshOther || (!others.length && !hasRunGetOther.current)) {
            othersList();
        }

        async function othersList() {
            setRefreshOther(true);
            let _type = 1;
            if(configure_type === 'operator') _type = 2;
            else if(configure_type === 'machine') _type = 3;

            const response = await fetchConfigureList(axiosInstance, _type);

            if(response.status === 'success') {
                setOthers(response.data);
            }

            setRefreshOther(false);
            hasRunGetOther.current = true;
        }
    }, [refreshOther, axiosInstance, others, hasRunGetOther, configure_type]);

    return (
        <>
            <div className="page-header">
                <h3 className="page-title"> {name} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">{name}</li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages.message &&
                                <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                            <div className="float-right">
                                <Link to={'create'} type="button"
                                      className="btn btn-info btn-icon-text">
                                    <Icon path={mdiPlus} size={0.7}/> {btn_add}
                                </Link>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th style={{width: "5%"}}>Lp.</th>
                                        <th style={{width: "31.6%"}}>Wyświetlana nazwa</th>
                                        {configure_type !== '' && <th style={{width: "31.6%"}}>{table_name}</th>}
                                        <th style={{width: "31.6%"}}>Data utworzenia</th>
                                        <th style={{width: "31.6%"}}>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {refreshOther && (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {others.map((order, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <label className={`badge badge-${order.color}`}>
                                                    {order.display_name}
                                                </label>
                                            </td>
                                            {configure_type !== '' && (
                                                <td>
                                                    <label
                                                        className={`badge badge-${order.order.color}`}>{order.order.display_name}</label>
                                                </td>
                                            )}
                                            <td>{order.created_at}</td>
                                            <td>
                                                <Link to={`/${uri}/edit/${order.id}`}
                                                      type="button" className="btn btn-primary btn-fw">
                                                    Edytuj
                                                </Link>
                                                <button type="button" className="btn btn-danger btn-fw"
                                                        onClick={() => handleRemove(order.id)}
                                                >Usuń</button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Configure;