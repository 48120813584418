import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useAxios from "../../api/useAxios";
import {fetchUserList} from "../../api/ApiUser";
import {fetchProjectDetailCreatePosition} from "../../api/ApiProject";
import {mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import {handleIsValid} from "../../helpers/globalHelpers";

const ProjectDetailModalAddPosition = ({show, setShow, dataModal, setDataProjectDetailModalPosition}) => {

    const axiosInstance = useAxios();
    const [users, setUsers] = useState([]);
    const [inputs, setInputs] = useState({
        project_position_number: '',
        project_position_name: '',
        project_position_user_id: '',
        project_position_type: dataModal.positionSelectId,
        is_new: 1
    });
    const [messages, setMessages] = useState({
        message: '', status: ''
    });

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    useEffect(() => {
        setInputs({
            project_position_number: '',
            project_position_name: '',
            project_position_user_id: '',
            project_position_type: 1,
            is_new: 1
        })
        setMessages({
            message: "",
            status: ''
        });
    }, [show])

    useEffect(() => {
        if(show) {
            getUsers();
        }

        async function getUsers() {
            const response = await fetchUserList(axiosInstance);

            if(response.status === 'success') {
                setUsers(response.users);
                handleChangeInput('project_position_type', dataModal.positionSelectId);
                handleChangeInput('project_position_user_id', response.users[0].id);
            }
        }
    }, [axiosInstance, show]);

    const handleSave = async () => {
        const response = await fetchProjectDetailCreatePosition(axiosInstance, dataModal.projectId, inputs);

        console.log(response);

        if(response.status === 'success') {
            setShow(false);
            setInputs({
                project_position_number: '',
                project_position_name: '',
                project_position_user_id: '',
                project_position_type: 1,
                is_new: 1
            })
            setMessages({
                message: "",
                status: ''
            });
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger'
            });
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>{dataModal.title}</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                {messages.message && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                <form className="forms-sample">
                    <div className="form-group">
                        <label htmlFor="example">Numer</label>
                        <input type="text" className="form-control" placeholder="Numer"
                               value={inputs.project_position_number}
                               onChange={(e) => handleChangeInput('project_position_number', e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Nazwa</label>
                        <input type="text" className={`form-control ${handleIsValid(inputs.project_position_name)}`} placeholder="Nazwa"
                               value={inputs.project_position_name}
                               onChange={(e) => handleChangeInput('project_position_name', e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="example">Osoba odpowiedzialna</label>
                        <select className="form-control"
                                onChange={(e) => handleChangeInput('project_position_user_id', e.target.value)}
                                defaultValue={inputs.project_position_user_id}
                        >
                            {users.map((user, i) => (
                                <option key={i} value={user.id}>{user.display_name}</option>
                            ))}
                        </select>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Zapisz
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ProjectDetailModalAddPosition;