import React, {useEffect, useState} from 'react';
import useAxios from "../../api/useAxios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {statusColor} from "../../helpers/Status";
import {fetchRole, fetchRoleCreate, fetchRoleUpdate} from "../../api/ApiRole";

const rolesList = [
    { id: "user_show", name: "Wyświetlanie użytkowników" },
    { id: "user_create_edit", name: "Edycja / Tworzenie użytkowników" },
    { id: "user_remove", name: "Usuwanie użytkowników" },
    { id: "role_show", name: "Wyświetlanie ról" },
    { id: "role_create_edit", name: "Edycja / Tworzenie ról" },
    { id: "role_remove", name: "Usuwanie ról" },
    { id: "project_show", name: "Wyświetlanie projektów" },
    { id: "project_create_edit", name: "Edycja / Tworzenie projektów" },
    { id: "project_remove", name: "Usuwanie projektów" },
    { id: "test_show", name: "Wyświetlanie test" },
    { id: "test_create_edit", name: "Edycja / Tworzenie test" },
    { id: "test_remove", name: "Usuwanie test" },
];

const RoleForm = ({_type}) => {
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const { id } = useParams();

    const idsPermissions = [];

    const [selectedPermissions, setSelectedPermissions] = useState(idsPermissions || []);
    const [messages, setMessages] = React.useState({message: '', status: ''});

    const [inputs, setInputs] = React.useState({
        display_name: '',
        bg_color: statusColor[0].id,
        ids_permissions: '[]'
    });

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    const dividedRoles = divideArrayIntoThree(rolesList);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (value === "all") {
            if (checked) {
                setSelectedPermissions(['all']);
            } else {
                setSelectedPermissions([]);
            }
        } else {
            if (checked) {
                setSelectedPermissions(prev => [...prev, value]);
            } else {
                setSelectedPermissions(prev => prev.filter(id => id !== value));
            }
        }
    };

    const handleCreateRole = async (e) => {
        e.preventDefault();
     //   handleChangeInput('ids_permissions', JSON.stringify(selectedPermissions));

        let _inputs = inputs;
        _inputs.ids_permissions = JSON.stringify(selectedPermissions);

        const response = await fetchRoleCreate(axiosInstance, _inputs);

        if(response.status === 'success') {
            navigate('/roles', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const handleUpdateRole = async (e) => {
        e.preventDefault();
      //  handleChangeInput('ids_permissions', JSON.stringify(selectedPermissions));

        let _inputs = inputs;
        _inputs.ids_permissions = JSON.stringify(selectedPermissions);

        const response = await fetchRoleUpdate(axiosInstance, id, _inputs);

        if(response.status === 'success') {
            navigate('/roles', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    useEffect(() => {
        if(_type === 'edit') {
            getRoleForId(id);
        }

        async function getRoleForId(roleId) {
            const response = await fetchRole(axiosInstance, roleId);

            if(response.status === 'success') {
                setInputs({
                    display_name: response.role.display_name,
                    bg_color: response.role.bg_color,
                    ids_permissions: JSON.parse(response.role.ids_permissions),
                });
                setSelectedPermissions(JSON.parse(response.role.ids_permissions));
            }
            else {
                navigate('/roles', {state: {message: response.message, status: 'danger'}});
            }
        }
    }, [_type])



    if(_type === 'edit' && inputs.display_name === "") {
        return "";
    }
    
    return (
        <>
        <div className="page-header">
            <h3 className="page-title">{_type === 'create' ? 'Dodaj rolę' : 'Edytuj rolę'} </h3>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/roles'}>Role</Link></li>
                    <li className="breadcrumb-item active"
                        aria-current="page">{_type === 'create' ? 'Dodaj rolę' : 'Edytuj rolę'}
                    </li>
                </ol>
            </nav>
        </div>

        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        {messages && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}

                        <form className="forms-sample" onSubmit={_type === 'create' ?  handleCreateRole : handleUpdateRole}>
                            <div className="form-group">
                                <label htmlFor="InputDisplay_name">Wyświetlana nazwa</label>
                                <input type="text" className="form-control" id="InputDisplay_name"
                                       value={inputs.display_name}
                                       onChange={(e) => handleChangeInput('display_name', e.target.value)}
                                       placeholder="Wyświetlana nazwa"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="InputBg_Color">Kolor roli</label>
                                <select className="form-control" id="InputBg_Color"
                                        name="bg_color" 
                                        onChange={(e) => handleChangeInput('bg_color', e.target.value)}
                                        defaultValue={inputs.bg_color}
                                >
                                    {statusColor.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <br/>
                            <div className="form-group" style={{marginBottom: '0.2rem'}} >
                                <label htmlFor="InputPermissions">Uprawnienia</label>
                            </div>
                            <div className="row">
                                {dividedRoles.map((roles, index) => (
                                    <div className="col-md-4" key={index}>
                                        {index === 0 && (
                                            <div className="form-check form-check-flat form-check-primary">
                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        value="all"
                                                        onChange={handleCheckboxChange}
                                                        checked={selectedPermissions.includes('all')}
                                                    />
                                                    <i className="input-helper"></i>
                                                    Wszystkie poniższe uprawnienia
                                                </label>
                                            </div>
                                        )}
                                        {roles.map((role) => (
                                            <div className="form-check form-check-flat form-check-primary"
                                                 key={role.id}>
                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        value={role.id}
                                                        onChange={handleCheckboxChange}
                                                        checked={selectedPermissions.includes(role.id)}
                                                    />
                                                    <i className="input-helper"></i>
                                                    {role.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            <br/><br/>
                            <button type="submit"
                                    className="btn btn-primary mr-2">{_type === 'create' ? 'Utwórz' : 'Edytuj'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const divideArrayIntoThree = (array) => {
    const groups = {};

    array.forEach((item) => {
        const prefix = item.id.split('_')[0];
        if (!groups[prefix]) {
            groups[prefix] = [];
        }
        groups[prefix].push(item);
    });

    const result = [[], [], []];

    let index = 0;

    Object.values(groups).forEach((group) => {
        group.forEach((item) => {
            result[index].push(item);
            index = (index + 1) % 3;
        });
    });

    return result;
};

export default RoleForm;