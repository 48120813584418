import {useContext, useEffect} from 'react';
import AxiosCreate from '../api/AxiosCreate';
import {AuthContext} from "../contexts/AuthContext";

const useAxios = () => {
    const {authState, refreshToken, logout} = useContext(AuthContext);


    useEffect(() => {
        const requestInterceptor = AxiosCreate.interceptors.request.use(
            config => {
                const storedAuth = localStorage.getItem('authState');
                if (storedAuth) {
                    const parsedAuth = JSON.parse(storedAuth);
                    config.headers['Authorization'] = `Bearer ${parsedAuth.token}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        const responseInterceptor = AxiosCreate.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !error.config._retry) {
                    error.config._retry = true;

                    console.log("ERROR LOGOWANIA")

                    // logout();
                    try {
                        const storedAuth = localStorage.getItem('authState');
                        if (!storedAuth) {
                            if (window.location.pathname !== '/login') {
                                window.location.href = '/login';
                            }
                        }
                        const parsedAuth = JSON.parse(storedAuth);
                        const token = parsedAuth.token;

                        AxiosCreate.defaults.headers['Authorization'] = `Bearer ${token}`;
                        AxiosCreate.defaults.headers['Authorization'] = `Bearer ${token}`;
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;

                        return AxiosCreate(originalRequest);
                    } catch (refreshError) {
                        console.error('Token refresh failed', refreshError);
                        // Można dodać dodatkową logikę, np. przekierowanie do strony logowania

                        if (window.location.pathname !== '/login') {
                            window.location.href = '/login';
                        }
                    }
                }
                return Promise.reject(error);
            }
        );

        // Cleanup function to remove interceptors when component unmounts
        return () => {
            AxiosCreate.interceptors.request.eject(requestInterceptor);
            AxiosCreate.interceptors.response.eject(responseInterceptor);
        };
    }, [authState.token, refreshToken]);

    return AxiosCreate;
}

export default useAxios;