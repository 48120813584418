import React, {Component, useContext, useEffect, useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import Icon from '@mdi/react';
import {mdiMenu, mdiBell, mdiMenuDown, mdiLogout, mdiSolarPanel} from '@mdi/js';
import {AuthContext} from "../contexts/AuthContext";

function Navbar({user, toggleTheme}) {
    const {logout} = useContext(AuthContext);


    const toggleOffcanvas = ()  => {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
    const toggleRightSidebar = () => {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }


    return (
        <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                <Link className="navbar-brand brand-logo-mini" to="/">LOGO</Link>
            </div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                <button className="navbar-toggler align-self-center" type="button"
                        onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                    <Icon path={mdiMenu} size={1}/>
                </button>
                <ul className="navbar-nav navbar-nav-right">
                    <Dropdown alignright={"true"} as="li" className="nav-item border-left">
                        <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer" onClick={() => toggleTheme()}>
                            <Icon path={mdiSolarPanel} size={0.7}/>
                        </Dropdown.Toggle>
                    </Dropdown>
                    <Dropdown alignright={"true"} as="li" className="nav-item border-left">
                        <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
                            <Icon path={mdiBell} size={0.7}/>
                            <span className="count bg-danger"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                            <h6 className="p-3 mb-0">Notifications</h6>
                            <Dropdown.Divider/>
                            <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-calendar text-success"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Event today</p>
                                    <p className="text-muted ellipsis mb-0">
                                        Just a reminder that you have an event today
                                    </p>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-settings text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className="preview-subject mb-1">Settings</h6>
                                    <p className="text-muted ellipsis mb-0">
                                        Update dashboard
                                    </p>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-link-variant text-warning"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className="preview-subject mb-1">Launch Admin</h6>
                                    <p className="text-muted ellipsis mb-0">
                                        New admin wow!
                                    </p>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <p className="p-3 mb-0 text-center">See all notifications</p>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown alignright={"true"} as="li" className="nav-item">
                        <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                            <div className="navbar-profile">
                                <img className="img-xs rounded-circle"
                                     src={require('../assets/images/default-avatar.jpg')} alt="profile"/>
                                <p className="mb-0 d-none d-sm-block navbar-profile-name">{user.display_name}</p>
                                <Icon path={mdiMenuDown} size={0.7}/>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                            <h6 className="p-3 mb-0">Profile</h6>
                            <Dropdown.Divider/>
                            {/*<Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">*/}
                            {/*    <div className="preview-thumbnail">*/}
                            {/*        <div className="preview-icon bg-dark rounded-circle">*/}
                            {/*            <i className="mdi mdi-settings text-success"></i>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="preview-item-content">*/}
                            {/*        <p className="preview-subject mb-1">Settings</p>*/}
                            {/*    </div>*/}
                            {/*</Dropdown.Item>*/}
                            <Dropdown.Divider/>
                            <Dropdown.Item href="!#" onClick={logout} className="preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <Icon path={mdiLogout} size={0.7} className={"text-danger"}/>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p className="preview-subject mb-1">Wyloguj się</p>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        onClick={toggleOffcanvas}>
                    <span className="mdi mdi-format-line-spacing"></span>
                </button>
            </div>
        </nav>
    );
}

export default Navbar;