import React, {useEffect, useRef, useState} from "react";
import {
    fetchProjectDetail, fetchProjectDetailDeletePart, fetchProjectDetailDeletePosition, fetchProjectDetailPositionPartUpdateStatus, fetchProjectPositionPartImportExcel, fetchProjectPositionPartUpdate
} from "../../api/ApiProject";
import useAxios from "../../api/useAxios";
import {
    mdiCloseCircle, mdiCog, mdiFileDocument, mdiPlus, mdiSettingsHelper
} from "@mdi/js";
import Icon from "@mdi/react";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import Attachment from "../Attachment";
import CustomSelect from "../CustomSelect";
import ModalAreYouSure from "../ModalAreYouSure";
import {YesOrNo} from "../../helpers/Status";
import {sortProjectPositionParts} from "../../helpers/projectHelper";

export const projectPosition = [{name: 'Forma wtryskowa', id: 1}, {name: 'Wykrojnik', id: 2}, {name: 'Giętnik', id: 3}, {name: 'Tłocznik', id: 4}, {name: 'Inne', id: 5}];

function ProjectDetail({
                           isActive,
                           setIsActive,
                           idProject,
                           setIdProject,
                           setRefreshProject,
                           showProjectDetailModalAddPosition,
                           setShowModalPositionAdd,
                           showProjectDetailModalAddPart,
                           setDataProjectDetailModalPosition,
                           setDataProjectDetailModalAddPart,
                           setShowProjectDetailModalAddPart,
                           projectPartStatuses,
                           projectPartCNCStatuses,
                           GetStatusCNCForPart,
                           handleDefaultYesOrNo
                       }) {
    const axiosInstance = useAxios();

    const [projectDetail, setProjectDetail] = useState(null);
    const [isRefresh, setIsRefresh] = useState(false);

    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [excelImportPositionId, setExcelImportPositionId] = useState(null);

    const [checkedPart, setCheckedPart] = useState([]);

    const detailRef = useRef(null);

    const [showModalAreYouSure, setShowModalAreYouSure] = useState(false);
    const [modalDataAreYouSure, setModalDataAreYouSure] = useState({title: '', body: '', id: null});


    const handleTabClick = (index, positionId) => {
        setIsLoading(true);
        setActiveTab(index);

        setTimeout(() => {
            setIsLoading(false);
        }, 250);
    };

    const handleAddPart = (idPosition, typeName) => {
        setShowProjectDetailModalAddPart(true)
        setDataProjectDetailModalAddPart({title: `Dodaj część do - ${typeName}`, positionId: idPosition, isEdit: false, idPart: null})
    }

    useEffect(() => {
        if (!projectDetail || isRefresh || (projectDetail && !showProjectDetailModalAddPosition) || (projectDetail && !showProjectDetailModalAddPart)) {
            getDetailProject();
        }

        async function getDetailProject() {
            if (!idProject) return;

            const response = await fetchProjectDetail(axiosInstance, idProject);

            if (response.status === 'success') {
                setProjectDetail(sortProjectPositionParts(response.project, true));
                handleShowHide(true);
                setRefreshProject(true);
            }
        }

    }, [axiosInstance, idProject, setIsActive, isRefresh, showProjectDetailModalAddPosition, showProjectDetailModalAddPart]);


    const handleChangeStatusForPart = async (idPart, statusId) => {
        const status_cnc = projectPartCNCStatuses.find((s) => s.kind_status === 'status_end');
        const status = projectPartStatuses.find((s) => parseInt(s.id) === parseInt(statusId));


        if (checkedPart.length > 0) {
            try {
                await Promise.all(checkedPart.map(id => fetchProjectPositionPartUpdate(axiosInstance, id, {status: statusId, status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id, id_machine: 0})));

                setIsRefresh(true);
                setCheckedPart([]);
            } catch (error) {
                console.error(error);
            }
        } else {
            const response = await fetchProjectPositionPartUpdate(axiosInstance, idPart, {status: statusId, status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id, id_machine: 0});
            // const response = await fetchProjectDetailPositionPartUpdateStatus(axiosInstance, idPart, statusId);

            if (response.status === 'success') {
                setIsRefresh(true);
            }
        }

    }

    const handleChangeYesOrNo = async (idPart, name, value) => {
        if (checkedPart.length > 0) {
            try {
                await Promise.all(checkedPart.map(id => fetchProjectPositionPartUpdate(axiosInstance, id, {[name]: value})));

                setIsRefresh(true);
                setCheckedPart([]);
            } catch (error) {
                console.error(error);
            }
        } else {
            const response = await fetchProjectPositionPartUpdate(axiosInstance, idPart, {[name]: value});

            if (response.status === 'success') {
                setIsRefresh(true);
            }
        }
    }

    const handleShowHide = (isShow) => {
        setIsActive(isShow);
        setIsRefresh(false);

        if (!isShow) {
            setIdProject(null);
            setProjectDetail(null)
        }
    }

    const handleClickOutside = (event) => {
        if (detailRef.current && !detailRef.current.contains(event.target) && !document.querySelector(".modal")?.contains(event.target) && !document.querySelector(".modal-backdrop")?.contains(event.target) && !document.querySelector(".select-box")?.contains(event.target) && !document.querySelector(".select-box.active")?.contains(event.target) && !document.querySelector(".my-select")?.contains(event.target)) {
            setIsActive(false);
            setIdProject(null);
            setProjectDetail(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleRemovePosition = async (idPosition) => {

        setModalDataAreYouSure({
            title: 'Usuwanie pozycji', body: 'Czy na pewno chcesz usunąć tę pozycję? Wszystkie części przypisane do tej pozycji zostaną usunięte.', id: idPosition
        });
        setShowModalAreYouSure(true);
    }

    const handleAcceptAreYouSure = async () => {
        if (modalDataAreYouSure.id === null) return;

        const response = await fetchProjectDetailDeletePosition(axiosInstance, modalDataAreYouSure.id);

        if (response.status === 'success') {
            setIsRefresh(true);
            setActiveTab(0);
            setShowModalAreYouSure(false);
            setModalDataAreYouSure({title: '', body: '', id: null});
        }
    }

    const handleEditPosition = async (idPosition) => {
        setShowModalPositionAdd(true);
    }

    const handleEditPart = async (idPart) => {
        setDataProjectDetailModalAddPart({title: 'Edytuj część', positionId: null, isEdit: true, idPart: idPart});
        setShowProjectDetailModalAddPart(true);
    }

    const handleFileClick = (positionId) => {
        setExcelImportPositionId(positionId);
        document.getElementById('excel-file').click()
    }

    const handleFileChange = async (e, activeTabId) => {
        const fileExcel = e.target.files[0];

        if (fileExcel && excelImportPositionId) {
            const formData = new FormData();
            formData.append('excel_file', fileExcel);

            const response = await fetchProjectPositionPartImportExcel(axiosInstance, excelImportPositionId, formData);

            if (response.status === 'success') {
                setIsRefresh(true);
                setActiveTab(activeTabId);
                setExcelImportPositionId(null)
            } else {
                console.log("EXCEL FILE ERR: ", response.message)
            }
        } else {
            console.log("BRAK PLIKU EXCEL");
        }
    }

    const GetStatusForPart = (IdStatus) => {
        const status = projectPartStatuses.find((s) => parseInt(s.id) === parseInt(IdStatus));

        if (!status) {
            return {
                color: 'outline-dark', display_name: 'Brak statusu'
            }
        }

        return {
            color: `outline-${status.color}`, display_name: status.display_name
        }
    }

    const handleRemovePart = async (idPart) => {
        const response = await fetchProjectDetailDeletePart(axiosInstance, idPart);

        if (response.status === 'success') {
            setIsRefresh(true);
        }
    }

    const handleCheckBoxChange = (e) => {
        const {value, checked} = e.target;

        if (checked) {
            setCheckedPart(prev => [...prev, value]);
        } else {
            setCheckedPart(prev => prev.filter(id => id !== value));
        }
    }

    return (<>
        <div className={`project__panel ${isActive ? 'active' : ''}`} ref={detailRef}>
            {projectDetail && <div className="row g-2">
                <div className="col-md-11">
                    <div className="card mb-3">
                        <div className="card-body" style={{padding: '0 1rem'}}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="preview-list">
                                        <div className="preview-item border-bottom">
                                            <div className="preview-thumbnail">
                                                <div className="preview-icon bg-primary">
                                                    <Icon path={mdiFileDocument} size={1}/>
                                                </div>
                                            </div>
                                            <div className="preview-item-content d-sm-flex flex-grow">
                                                <div className="flex-grow">
                                                    <h6 className="preview-subject">Projekt: {projectDetail.project_name}</h6>
                                                    <p className="text-muted mb-0">{projectDetail.project_client_name}</p>
                                                </div>
                                                <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                    <p className="text-muted"
                                                       id="ProjectCreatedAt">{projectDetail.project_created_at}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 py-2">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="Input_Position">Wybór pozycji</label>
                                                <select className="form-control"

                                                        onChange={(e) => setDataProjectDetailModalPosition((prevState) => ({
                                                            ...prevState, title: e.target.options[e.target.selectedIndex].text, positionSelectId: e.target.value
                                                        }))}
                                                >
                                                    {projectPosition.map((position, i) => (<option key={i}
                                                                                                   value={position.id}>{position.name}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <br/>
                                                <button type="button"
                                                        className="btn btn-info btn-icon-text"
                                                        onClick={() => setShowModalPositionAdd(true)}
                                                >
                                                    <Icon path={mdiPlus}
                                                          className={"btn-icon-prepend"}
                                                          size={0.7}/>
                                                    Dodaj pozycję
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1">
                    <Icon
                        path={mdiCloseCircle} size={1.2}
                        title={"Zamknij"}
                        className={"cursor-pointer text-danger"}
                        onClick={() => handleShowHide(false)}/>
                </div>
                {typeof projectDetail !== "undefined" && projectDetail.project_positions.length > 0 && (<div className="col-md-12" id="ContainerPositionList">
                    <div className="card text-center">
                        <div className="card-header project__panel--header">
                            <ul className="nav nav-tabs card-header-tabs" id="ProjectPositionList"
                                role="tablist">
                                {projectDetail.project_positions.map((position, i) => (<li className="nav-item" key={`project_detail_position_${i}`}>
                                    <button
                                        title={`${position.display_name} - ${position.display_number === '' ? 'Brak' : position.display_number}`}
                                        className={`text-small nav-link ${activeTab === i ? 'active' : ''}`}
                                        onClick={() => handleTabClick(i, position.position_id)}
                                        role="tab"
                                        aria-selected={activeTab === i}
                                    >
                                        <span className="text-small">{position.type}</span>
                                        <br/>
                                        NR: {position.display_number === '' ? 'Brak' : position.display_number}
                                    </button>
                                </li>))}
                            </ul>
                        </div>
                        <div className="card-body tab-content card-body-custom-project-detail"
                             id="ProjectPositionTab">
                            {isLoading ? (<div className={"text-center"}>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>) : (projectDetail.project_positions.map((position, i) => (<div
                                    key={i}
                                    className={`tab-pane fade ${activeTab === i ? 'show active' : ''}`}
                                    id={`tab${i}-content`}
                                    role="tabpanel"
                                    aria-labelledby={`tab${i}-tab`}
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                                    <span
                                                        className="preview-subject text-small">Osoba odpowiedzialna:</span>
                                            <div
                                                className={`badge badge-${position.user.color} badge-small-custom`}>{position.user.display_name}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="float-right d-flex " style={{gap: '5px'}}>
                                                {/*<button*/}
                                                {/*    type="button"*/}
                                                {/*    className="btn btn-success btn-sm"*/}
                                                {/*    onClick={() => handleEditPosition(position.id_position)}*/}
                                                {/*>*/}
                                                {/*    Edytuj pozycję*/}
                                                {/*</button>*/}
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleRemovePosition(position.id_position)}
                                                >
                                                    Usuń pozycję
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive table-scroll mt-3">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th style={{width: '2%'}}></th>
                                                <th style={{width: '14.2%'}}>Numer części</th>
                                                <th style={{width: '14.2%'}}>Nazwa części</th>
                                                <th style={{width: '14.2%'}}>Ilość</th>
                                                <th style={{width: '14.2%'}}>Status</th>
                                                <th style={{width: '14.2%'}}>Status CNC</th>
                                                <th style={{width: '14.2%'}}>Hartowanie<br/>Azotowanie</th>
                                                <th style={{width: '14.2%'}}>Materiał</th>
                                                <th style={{width: '14.2%'}}>Załącznik</th>
                                                <th style={{width: '14.2%'}}>Akcje</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {position.project_position_part.map((part, j) => (<tr key={`project_detail_position_${i}_part_${j}`}
                                                                                                  style={{cursor: 'pointer'}}>
                                                <td>
                                                    <div
                                                        className="form-check form-check-flat form-check-primary">
                                                        <label
                                                            className="form-check-label">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                value={part.id_position_part}
                                                                onChange={handleCheckBoxChange}
                                                                checked={checkedPart.includes(part.id_position_part)}
                                                            />
                                                            <i className="input-helper"></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td data-showmodal="ModalSectionShow"
                                                    data-positionpartid={part.id_position_part}>
                                                    {part.display_number}
                                                </td>
                                                <td data-showmodal="ModalSectionShow"
                                                    data-positionpartid={part.id_position_part}>
                                                    {part.display_name}
                                                </td>
                                                <td data-showmodal="ModalSectionShow"
                                                    data-positionpartid={part.id_position_part}>
                                                    {part.quantity}
                                                </td>
                                                <td data-showmodal="ModalSectionShow"
                                                    data-positionpartid={part.id_position_part}>
                                                    <CustomSelect
                                                        defaultValue={GetStatusForPart(part.status)}
                                                        onSelect={(selected) => handleChangeStatusForPart(part.id_position_part, selected.id)}
                                                    >
                                                        {projectPartStatuses.map((option, i) => (<div
                                                            key={`my_select_o_${i}`}
                                                            className={`cursor-pointer badge badge-${option.color}`}
                                                            option={option}
                                                        >
                                                            {option.display_name}
                                                        </div>))}
                                                    </CustomSelect>
                                                </td>
                                                <td>
                                                    <CustomSelect
                                                        defaultValue={GetStatusCNCForPart(part.status_cnc)}
                                                        clickable={false}
                                                    ></CustomSelect>
                                                </td>
                                                <td>
                                                    <div
                                                        className={"row"}>
                                                        <div
                                                            className={"col-5"}>
                                                            <CustomSelect
                                                                title={"Hartowanie"}
                                                                defaultValue={handleDefaultYesOrNo(part.temper)}
                                                                onSelect={(selected) => handleChangeYesOrNo(part.id_position_part, 'temper', selected.id)}
                                                                customClass={"badge-sm"}
                                                                isOutline={true}
                                                            >
                                                                {YesOrNo.map((yn, i) => (
                                                                    <div
                                                                        key={`my_select_machine_${part.id_position_part}_c_h_${i}`}
                                                                        className={`cursor-pointer badge badge-${yn.color}`}
                                                                        option={yn}
                                                                    >
                                                                        {yn.display_name}
                                                                    </div>
                                                                ))}
                                                            </CustomSelect>
                                                        </div>
                                                        <div
                                                            className={"col-5"}>
                                                            <CustomSelect
                                                                title={"Azotowanie"}
                                                                defaultValue={handleDefaultYesOrNo(part.azotize)}
                                                                onSelect={(selected) => handleChangeYesOrNo(part.id_position_part, 'azotize', selected.id)}
                                                                customClass={"badge-sm"}
                                                                isOutline={true}
                                                            >
                                                                {YesOrNo.map((yn,i) => (
                                                                    <div
                                                                        key={`my_select_machine_${part.id_position_part}_a_${i}`}
                                                                        className={`cursor-pointer badge badge-${yn.color}`}
                                                                        option={yn}
                                                                    >
                                                                        {yn.display_name}
                                                                    </div>
                                                                ))}
                                                            </CustomSelect>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-showmodal="ModalSectionShow"
                                                    data-positionpartid={part.id_position_part}>
                                                    {part.name_material}
                                                </td>
                                                <td>
                                                    <Attachment
                                                        hyperlink={part.hyperlink}/>
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="btn btn-primary"
                                                            id="dropdownMenuButton1">
                                                            <Icon
                                                                path={mdiCog}
                                                                size={0.7}/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => handleEditPart(part.id_position_part)}
                                                            >
                                                                Edytuj</Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => handleRemovePart(part.id_position_part)}>Usuń</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <br/>
                                    <div style={{display: "flex", gap: '7px'}}>
                                        <button
                                            type="button"
                                            className="btn btn-inverse-primary btn-fw"
                                            onClick={() => handleAddPart(position.id_position, position.type)}
                                        >
                                            Dodaj część
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-inverse-success btn-fw"
                                            onClick={() => handleFileClick(position.id_position)}
                                        >
                                            Importuj EXCEL
                                        </button>
                                        <input type="file" id="excel-file" accept=".xlsx, .xls"
                                               style={{display: 'none'}}
                                               onChange={(e) => handleFileChange(e, activeTab)}
                                        />
                                    </div>
                                </div>
                            )))}
                        </div>
                    </div>
                </div>)}
            </div>}
            {!projectDetail && <div className={"text-center"}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
        </div>

        <ModalAreYouSure
            show={showModalAreYouSure}
            setShow={setShowModalAreYouSure}
            btn_name_accept={"Usuń"}
            handleAccept={handleAcceptAreYouSure}
            modalData={modalDataAreYouSure}
        />
    </>)
}

export default ProjectDetail;