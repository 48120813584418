import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useAxios from "../api/useAxios";
import {fetchGetPositionPart} from "../api/ApiProject";
import {priorityStatus} from "../helpers/PriorityStatus";
import {getCurrentDateTime} from "../helpers/convertDate";
import {complicatedStatus, TYPE_STATUS_CNC, TYPE_STATUS_PROJECT_PART_ID} from "../helpers/Status";
import Attachment from "./Attachment";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";

const PartModalDetail = ({ part_id, show, setShow, statuses }) => {
    const axiosInstance = useAxios();

    const [part, setPart] = useState([]);
    const [position, setPosition] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        if(show) {
            getPart();
        }

        async function getPart() {
            const response = await fetchGetPositionPart(axiosInstance, part_id);

            if(response.status === 'success') {
                setPart(response.position_part);
                setPosition(response.position);
                setProject(response.project);
            }
        }
    }, [show]);

    const handlePriority = (priority_id, status_id) => {
        const statusesNormal = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
        const statusNormal = statusesNormal.find(s => parseInt(s.id) === parseInt(status_id));

        if(!statusNormal || statusNormal.kind_status !== 'status_move_cnc') {
            return (
                "-"
            )
        }

        const priority = priorityStatus.find(p => p.id === parseInt(priority_id));

        if (!priority) {
            return (
                <div
                    className={`badge badge-outline-${priorityStatus[0].color}`}>
                    {priorityStatus[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-outline-${priority.color}`}>
                {priority.display_name}
            </div>
        )
    }

    const handleStatus = (status_id_cnc, status_id) => {
        const statusesCNC = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const status = statusesCNC.find(s => parseInt(s.id) === parseInt(status_id_cnc));
        const statusesNormal = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
        const statusNormal = statusesNormal.find(s => parseInt(s.id) === parseInt(status_id));



        if(!statusNormal || statusNormal.kind_status !== 'status_move_cnc') {
            return (
                "-"
            )
        }

        if (!status) {
            return (
                <div
                    className={`badge badge-${statuses[0].color}`}>
                    {statuses[0].display_name}
                </div>
            )
        }


        return (
            <div
                className={`badge badge-${status.color}`}>
                {status.display_name}
            </div>
        )
    }

    const handleComplicated = (complicated_id, status_id) => {
        const complicated = complicatedStatus.find(c => c.id === parseInt(complicated_id));
        const statusesNormal = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
        const statusNormal = statusesNormal.find(s => parseInt(s.id) === parseInt(status_id));

        if(!statusNormal || statusNormal.kind_status !== 'status_move_cnc') {
            return (
                "-"
            )
        }

        if (!complicated) {
            return (
                <div
                    className={`badge badge-outline-${complicatedStatus[0].color}`}>
                    {complicatedStatus[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-outline-${complicated.color}`}>
                {complicated.display_name}
            </div>
        )
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Informacje o części</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                <p>Nazwa projektu: <strong>{project.display_name}</strong></p>
                <p>Numer | Nazwa pozycji: <strong>{position.display_number} | {position.display_name}</strong></p>
                <br />
                <p>Numer | Nazwa części: <strong>{part.display_number} | {part.display_name}</strong></p>
                <p>Ilość: <strong>{part.quantity}</strong></p>
                <p>Wymiary: <strong>{part.dimensions}</strong></p>
                <p>Nazwa materiału: <strong>{part.name_material}</strong></p>
                <p>Zamówienie materiału: <strong>{part.material_order === "1" ? "TAK" : "NIE"}</strong></p>
                <p>Hartowanie: <strong>{part.temper === "0" ? "NIE" : "TAK"}</strong></p>
                <p>Azotowanie: <strong>{part.azotize === "0" ? "NIE" : "TAK"}</strong></p>
                <br />
                <p>Status CNC: {handleStatus(part.status_cnc, part.status)}</p>
                <p>Priorytet: {handlePriority(part.priority, part.status)}</p>
                <p>Stopień skomplikowania: {handleComplicated(part.complicated, part.status)}</p>
                <p>Czas rozpoczęcia: {getCurrentDateTime(part.time_start)}</p>
                <p>Czas zakończenia: {getCurrentDateTime(part.time_end)}</p>
                <p>Opis: {part.description === "" ? "Brak opisu" : part.description}</p>
                <Attachment hyperlink={part.hyperlink} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShow(false)}>Zamknij</button>
            </Modal.Footer>
        </Modal>
    );
}

export default PartModalDetail;