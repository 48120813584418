import React, {useEffect, useRef, useState} from 'react';
import useAxios from "../../api/useAxios";
import {
    fetchFinishPositionPartCNC,
    fetchProjectCNCPerMachineForNoStatus,
    fetchProjectCNCToBeDone,
    fetchProjectPositionPartUpdate
} from "../../api/ApiProject";
import {complicatedStatus, TYPE_STATUS_CNC, TYPE_STATUS_PROJECT_PART_ID, YesOrNo} from "../../helpers/Status";
import {getCurrentDateTime} from "../../helpers/convertDate";
import {priorityStatus} from "../../helpers/PriorityStatus";
import Attachment from "../../components/Attachment";
import {mdiBackupRestore, mdiClock, mdiMoveResizeVariant} from "@mdi/js";
import Icon from "@mdi/react";
import PartModalDetail from "../../components/PartModalDetail";
import CustomSelect from "../../components/CustomSelect";
import IconWithTooltip from "../../components/projects/IconWithTooltip";

import {DragDropContext, Draggable} from 'react-beautiful-dnd';
import {DroppableCustom} from "../../components/DroppableCustom";
import TextWithTooltip from "../../components/TextWitchTooltip";
import {sortProjectPositionParts, sortToBeDoneProject} from "../../helpers/projectHelper";


const CNCMachining = ({title, statuses, machines}) => {
    const axiosInstance = useAxios();

    const [partModalDetailShow, setPartModalDetailShow] = useState(false);
    const [partId, setPartId] = useState(null);


    const [toBeDoneProjectPart, setToBeDoneProjectPart] = useState([]);
    const [toBeDoneProjectCheck, setToBeDoneProjectCheck] = useState([]);

    const [finishProjectPart, setFinishProjectPart] = useState([]);

    const [dataForMachine, setDataForMachine] = useState(null);

    const hasRefreshToBeDone = useRef(false);
    const hasRefreshFinish = useRef(false);
    const [toBeDoneRefresh, setToBeDoneRefresh] = useState(false);
    const [dataMachineRefresh, setDataMachineRefresh] = useState(false);
    const [finishRefresh, setFinishRefresh] = useState(false);


    const [showProjectPositionPart, setShowProjectPositionPart] = useState([]);
    const handleChangeShowProjectPositionPart = (name, value) => {
        setShowProjectPositionPart((prevState) => ({
            ...prevState, [name]: value
        }));
    }

    const handleUpdateMachineForToBeDone = async (id_part, id_machine) => {
        const status_cnc = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const firstStatus = status_cnc.find(s => s.kind_status === 'status_start');

        if (toBeDoneProjectCheck.length > 0) {
            try {
                const updatedData = toBeDoneProjectCheck.reduce((acc, id) => {
                    return acc.map((project) => ({
                        ...project,
                        position_parts: project.position_parts.filter(part => part.id !== id)
                    })).filter(project => project.position_parts.length > 0);
                }, toBeDoneProjectPart);

                setToBeDoneProjectPart(updatedData);

                await Promise.all(toBeDoneProjectCheck.map(id => {
                    return fetchProjectPositionPartUpdate(axiosInstance, id, {
                        id_machine: id_machine,
                        status_cnc: firstStatus.id,
                        time_start: Date.now(),
                        priority: priorityStatus[0].id,
                        is_last_element_position: 1
                    });

                })).then((responses) => {
                    const allSuccessful = responses.every(response => response.status === 'success');

                    if (!allSuccessful) {
                        setToBeDoneRefresh(true);
                        setDataMachineRefresh(true);
                    } else {
                        setDataMachineRefresh(true);
                    }

                    setToBeDoneProjectCheck([]);
                });
            } catch (error) {
                console.error(error);
            }
        }
        else {
            const updateData = toBeDoneProjectPart.map((project) => ({
                ...project,
                position_parts: project.position_parts.filter(part => part.id !== id_part)
            })).filter(project => project.position_parts.length > 0);
            setToBeDoneProjectPart(updateData);

            const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
                id_machine: id_machine,
                status_cnc: firstStatus.id,
                time_start: Date.now(),
                priority: priorityStatus[0].id,
                is_last_element_position: 1
            });

            if (response.status === 'success') {
                setDataMachineRefresh(true);
            }
        }
    }

    const handleUpdateToBeDoneComplicated = async (id_part, id_complicated) => {
        if (toBeDoneProjectCheck.length > 0) {
            try {
                const updatedData = toBeDoneProjectCheck.reduce((acc, id) => {
                    return acc.map((project) => ({
                        ...project,
                        position_parts: project.position_parts.map(part =>
                            part.id === id
                                ? {...part, complicated: id_complicated}
                                : part)
                    }))
                }, toBeDoneProjectPart);

                setToBeDoneProjectPart(updatedData);

                await Promise.all(toBeDoneProjectCheck.map(id => {
                    return fetchProjectPositionPartUpdate(axiosInstance, id, {
                        complicated: id_complicated
                    });

                })).then((responses) => {
                    const allSuccessful = responses.every(response => response.status === 'success');

                    if (!allSuccessful) {
                        setToBeDoneRefresh(true);
                    }
                    setToBeDoneProjectCheck([]);
                });
            } catch (error) {
                console.error(error);
            }
        }
        else {
            const updateData = toBeDoneProjectPart.map(project => ({
                ...project,
                position_parts: project.position_parts.map(part =>
                    part.id === id_part
                        ? {...part, complicated: id_complicated}
                        : part
                )
            }));
            setToBeDoneProjectPart(updateData);

            const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
                complicated: id_complicated
            });

            if (response.status !== 'success') {
                setToBeDoneRefresh(true);
            }
        }
    }

    const handleUpdateToBeDoneYesOrNo = async (id_part, name, id_yes_or_no) => {
        if (toBeDoneProjectCheck.length > 0) {
            try {
                const updatedData = toBeDoneProjectCheck.reduce((acc, id) => {
                    return acc.map((project) => ({
                        ...project,
                        position_parts: project.position_parts.map(part =>
                            part.id === id
                                ? {...part, [name]: id_yes_or_no}
                                : part)
                    }))
                }, toBeDoneProjectPart);

                setToBeDoneProjectPart(updatedData);

                await Promise.all(toBeDoneProjectCheck.map(id => {
                    return fetchProjectPositionPartUpdate(axiosInstance, id, {
                        [name]: id_yes_or_no
                    });

                })).then((responses) => {
                    const allSuccessful = responses.every(response => response.status === 'success');

                    if (!allSuccessful) {
                        setToBeDoneRefresh(true);
                    }
                    setToBeDoneProjectCheck([]);
                });
            } catch (error) {
                console.error(error);
            }
        }
        else {
            const updateData = toBeDoneProjectPart.map(project => ({
                ...project,
                position_parts: project.position_parts.map(part =>
                    part.id === id_part
                        ? {...part, [name]: id_yes_or_no}
                        : part
                )
            }));
            setToBeDoneProjectPart(updateData);

            const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
                [name]: id_yes_or_no
            });

            if (response.status !== 'success') {
                setToBeDoneRefresh(true);
            }
        }
    }
    const handleUpdateMachine = async (id_part, name, id_yes_or_no) => {
        const updateData = dataForMachine.map((machine) => {
            return machine.map(project => {
                if (project.position_parts && parseInt(project.position_parts.id) === parseInt(id_part)) {
                    return {
                        ...project,
                        position_parts: {
                            ...project.position_parts,
                            [name]: id_yes_or_no
                        }
                    };
                }
                return project;
            });
        });
        setDataForMachine(updateData);

        const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
            [name]: id_yes_or_no
        });

        if (response.status !== 'success') {
            setDataMachineRefresh(true);
        }
    }
    const handleChangeStatusCNC = async (id_part, value, id_machine) => {
        const status_cnc = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const status = status_cnc.find(s => s.id === value);
        const performed = machines.find(m => m.id === id_machine);

        let updateData;

        if (status.kind_status === 'status_end') {
            updateData = dataForMachine.map(machine =>
                machine.filter(project =>
                    !(project.position_parts && parseInt(project.position_parts.id) === parseInt(id_part))
                )
            );
        } else {
            updateData = dataForMachine.map(machine =>
                machine.map(project => {
                    if (project.position_parts && parseInt(project.position_parts.id) === parseInt(id_part)) {
                        return {
                            ...project,
                            position_parts: {
                                ...project.position_parts,
                                status_cnc: value
                            }
                        };
                    }
                    return project;
                })
            );
        }
        setDataForMachine(updateData);

        let data = {};
        if (status.kind_status === 'status_end') {
            data = {
                status_cnc: value,
                time_end: Date.now(),
                performed: `${performed.display_name} | ${performed.order.display_name} | ${performed.order2.display_name}`
            }
        } else {
            data = {
                status_cnc: value,
            }
        }

        const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, data);

        if (response.status === 'success' && status.kind_status === 'status_end') {
            setFinishRefresh(true);
        }
    }

    const handleCheckBoxChange = (e) => {
        const {value, checked} = e.target;

        if (checked) {
            setToBeDoneProjectCheck(prev => [...prev, value]);
        } else {
            setToBeDoneProjectCheck(prev => prev.filter(id => id !== value));
        }
    }

    const handleComplicated = (id_complicated, part_id, isClickable = true) => {
        const complicated = complicatedStatus.find(c => c.id === parseInt(id_complicated));

        if (!complicated) {
            return (
                <div
                    className={`badge badge-outline-${complicatedStatus[0].color}`} style={{cursor: 'pointer'}}>
                    {complicatedStatus[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-outline-${complicated.color}`} style={{cursor: 'pointer'}}>
                {complicated.display_name}
            </div>
        )
    }

    const handleBackToBeDone = async (id_part) => {
        const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
            id_machine: 0,
            time_start: '',
            status_cnc: 0,
            complicated: complicatedStatus[0].id,
            position_element: null
        });

        if (response.status === 'success') {
            setToBeDoneRefresh(true);
            setDataMachineRefresh(true);
        }
    }

    const handleBackToMachine = async (id_part) => {
        const status_cnc = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const firstStatus = status_cnc.find(s => s.display_name === 'Oczekuje na maszynę');

        const response = await fetchProjectPositionPartUpdate(axiosInstance, id_part, {
            status_cnc: firstStatus.id,
            time_end: '',
            performed: '',
            position_element: null
        });

        if (response.status === 'success') {
            setDataMachineRefresh(true);
            setFinishRefresh(true);
        }
    }

    useEffect(() => {
        if (toBeDoneRefresh || !hasRefreshToBeDone.current) {
            getToBeDoneProjectPart();
        }

        async function getToBeDoneProjectPart() {
            const status = statuses.find(s => s.kind_status === 'status_move_cnc' && parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
            const response = await fetchProjectCNCToBeDone(axiosInstance, status.id);

            if (response.status === 'success') {
                setToBeDoneProjectPart(sortToBeDoneProject(response.projects));

                let projectsPartShowObj = {}
                for (const index in response.projects) {
                    projectsPartShowObj[`id_index_${index}`] = false;
                }

                setShowProjectPositionPart(projectsPartShowObj);
            }

            hasRefreshToBeDone.current = true;
            setToBeDoneRefresh(false);
        }
    }, [toBeDoneRefresh, hasRefreshToBeDone])

    useEffect(() => {
        if (machines.length > 0 || dataMachineRefresh) {
            getDataForMachine();
        }

        async function getDataForMachine() {
            const status_cnc = statuses.find(s => s.kind_status === 'status_end' && parseInt(s.type_status) === TYPE_STATUS_CNC);

            let arrayForMachine = [];
            for (let i = 0; i < machines.length; i++) {
                arrayForMachine.push([]);
            }

            for (const [index, machine] of machines.entries()) {
                const response = await fetchProjectCNCPerMachineForNoStatus(axiosInstance, status_cnc.id, machine.id);

                if (response.status === 'success') {
                    arrayForMachine[index] = response.projects;
                }
            }

            setDataForMachine(arrayForMachine);
            setDataMachineRefresh(false);
        }

    }, [dataMachineRefresh, machines])

    useEffect(() => {
        if (finishRefresh || !hasRefreshFinish.current) {
            getFinish();
        }

        async function getFinish() {
            const status_cnc = statuses.find(s => s.kind_status === 'status_end' && parseInt(s.type_status) === TYPE_STATUS_CNC);
            const status = statuses.find(s => s.kind_status === 'status_end' && parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);
            const response = await fetchFinishPositionPartCNC(axiosInstance, status.id, status_cnc.id);

            if (response.status === 'success') {
                setFinishProjectPart(response.projects);
            }

            hasRefreshFinish.current = true;
            setFinishRefresh(false);
        }
    }, [finishRefresh, hasRefreshFinish]);

    // Default VALUE
    const handleDefaultComplicated = (id_complicated) => {
        const complicated = complicatedStatus.find(c => c.id === parseInt(id_complicated));

        if (!complicated) {
            return {
                color: complicatedStatus[0].color,
                display_name: complicatedStatus[0].display_name
            }
        }

        return complicated;
    }
    const handleDefaultMaterialOrder = (id) => {
        if (id === 'Na stanie') {
            return {
                color: 'success',
                display_name: 'Na stanie'
            }
        } else {
            return {
                color: 'warning',
                display_name: 'Zamówiono'
            }
        }
    }
    const handleDefaultYesOrNo = (id) => {
        const yn = YesOrNo.find(o => o.id === parseInt(id));

        if (!yn) {
            return {
                color: YesOrNo[0].color,
                display_name: YesOrNo[0].display_name
            }
        }

        return yn;
    }
    const handleDefaultStatusCNC = (idStatusCNC) => {
        const statusesCNC = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const status = statusesCNC.find((s) => parseInt(s.id) === parseInt(idStatusCNC));

        if (!status) {
            return {
                color: statusesCNC[0].color,
                display_name: statusesCNC[0].display_name
            }
        }

        return status;
    }
    const handleDefaultPriority = (idPriority) => {
        const priority = priorityStatus.find(p => p.id === parseInt(idPriority));

        if (!priority) {
            return {
                color: priorityStatus[0].color,
                display_name: priorityStatus[0].display_name
            }
        }

        return priority
    }
    //

    const handleShowPartModalDetail = (part_id) => {
        setPartId(part_id);
        setPartModalDetailShow(true);
    }


    const onDragEnd = async (result) => {
        console.log('Drag result:', result);

        const {source, destination, draggableId} = result;

        if (!destination) {
            return;
        }

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }


        const updatedDataForMachine = [...dataForMachine];
        const sourceMachineIndex = machines.findIndex(
            (machine) => machine.id === source.droppableId
        );
        const destinationMachineIndex = machines.findIndex(
            (machine) => machine.id === destination.droppableId
        );

        const [movedItem] = updatedDataForMachine[sourceMachineIndex].splice(source.index, 1);

        movedItem.position_parts.id_machine = destination.droppableId;
        updatedDataForMachine[destinationMachineIndex].splice(destination.index, 0, movedItem);

        setDataForMachine(updatedDataForMachine);

        let arr = [];
        dataForMachine.forEach((data, index) => {
            data.forEach((project, i) => {
                arr.push({
                    id: project.position_parts.id,
                    id_machine: project.position_parts.id !== movedItem.position_parts.id ? project.position_parts.id_machine : destination.droppableId,
                    position_element: i + 1,
                });
            });
        });


        const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');

        const response = await fetchProjectPositionPartUpdate(axiosInstance, movedItem.position_parts.id, {
            updated_at: currentDateTime,
            is_new_position: 1,
            new_position_elements: JSON.stringify(arr)
        });

        if (response.status !== 'success') {
            setDataMachineRefresh(true);
        }
    };


    return (
        <>
            <div className="page-header">
                <h3 className="page-title"> Obróbka CNC</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Obróbka CNC</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Zlecenia do wykonania</h4>

                            <div className="table-responsive pb-3">
                                <table className="table no-tbody-border">
                                    <thead>
                                    <tr>
                                        <th style={{width: "5%"}}>Lp.</th>
                                        <th style={{width: "23.75%"}}>Nazwa projektu</th>
                                        <th style={{width: "23.75%"}}>Klient</th>
                                        <th style={{width: "23.75%"}}>Numer pozycji</th>
                                        <th style={{width: "23.75%"}}>Nazwa pozycji</th>
                                        <th style={{width: "10.75%"}}>Ilość części</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {toBeDoneRefresh && (<tr>
                                        <td colSpan="4" className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>)}
                                    {toBeDoneProjectPart.map((toBeDoneProject, i) => (
                                        <React.Fragment key={`tobedoneproject_${i}`}>
                                            <tr className={'hover'}>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                    {i + 1}
                                                </td>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                    <TextWithTooltip text={toBeDoneProject.project_name}/>
                                                </td>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                {/*{toBeDoneProject.project_client_name}*/}
                                                    <TextWithTooltip text={toBeDoneProject.project_client_name}/>
                                                </td>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                    {toBeDoneProject.position_number ? <TextWithTooltip text={toBeDoneProject.position_number}/> : 'Brak'}
                                                </td>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                    <TextWithTooltip text={toBeDoneProject.position_name}/>
                                                </td>
                                                <td className={"table_clickable"}
                                                    onClick={() => handleChangeShowProjectPositionPart(`id_index_${i}`,
                                                        !showProjectPositionPart[`id_index_${i}`])
                                                    }
                                                >
                                                    <strong>{toBeDoneProject.position_parts.length}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6} style={{padding: 0}}>
                                                    <div
                                                        className={`collapsible-content ${showProjectPositionPart[`id_index_${i}`] ? 'expanded' : ''}`}
                                                        style={{padding: '0 2rem'}}>
                                                        <table className="table mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th style={{width: "16.5%"}}>Część<br/>Numer
                                                                    |
                                                                    Nazwa
                                                                </th>
                                                                <th style={{width: "10.5%"}}>Ilość</th>
                                                                <th style={{width: "10.5%"}}>Materiał</th>
                                                                <th style={{width: "10.5%"}}>Materiał<br/>zamówiony</th>
                                                                <th style={{width: "10.5%"}}>Stopień<br/>Trudności</th>
                                                                <th style={{width: "18.5%"}}>Maszyna</th>
                                                                <th style={{width: "10.5%"}}>Załącznik</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {toBeDoneProject.position_parts.map((part) => (
                                                                <tr key={part.id}
                                                                    className={'hover'}>
                                                                    <td>
                                                                        <div className="form-check form-check-flat form-check-primary">
                                                                            <label className="form-check-label">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    value={part.id}
                                                                                    onChange={handleCheckBoxChange}
                                                                                    checked={toBeDoneProjectCheck.includes(part.id)}
                                                                                />
                                                                                <i className="input-helper"></i>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td onClick={() => handleShowPartModalDetail(part.id)}>{part.display_number} | {part.display_name}</td>
                                                                    <td onClick={() => handleShowPartModalDetail(part.id)}>{part.quantity}</td>
                                                                    <td onClick={() => handleShowPartModalDetail(part.id)}>{part.name_material}</td>

                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Materiał zamówiony"}
                                                                            defaultValue={handleDefaultMaterialOrder(part.material_order)}
                                                                            onSelect={(selected) => handleUpdateToBeDoneYesOrNo(part.id, 'material_order', selected.id)}
                                                                            customClass={"badge-sm"}
                                                                            isOutline={true}
                                                                        >
                                                                            <div
                                                                                className={`cursor-pointer badge badge-success`}
                                                                                option={{id: 'Na stanie'}}
                                                                            >
                                                                                Na
                                                                                stanie
                                                                            </div>
                                                                            <div
                                                                                className={`cursor-pointer badge badge-warning`}
                                                                                option={{id: 'Zamówiono'}}
                                                                            >
                                                                                Zamówiono
                                                                            </div>
                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Wybierz stopień trudności"}
                                                                            defaultValue={handleDefaultComplicated(part.complicated)}
                                                                            onSelect={(selected) => handleUpdateToBeDoneComplicated(part.id, selected.id)}
                                                                            isOutline={true}
                                                                        >
                                                                            {complicatedStatus.map((c, ind) => (
                                                                                <div
                                                                                    key={`my_select_st_${i}_${ind}_${part.id}`}
                                                                                    className={`cursor-pointer badge badge-${c.color}`}
                                                                                    option={c}
                                                                                >
                                                                                    {c.display_name}
                                                                                </div>
                                                                            ))}

                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Wybierz maszynę"}
                                                                            defaultValue={{
                                                                                color: 'dark',
                                                                                display_name: 'Wybierz maszynę'
                                                                            }}
                                                                            onSelect={(selected) => handleUpdateMachineForToBeDone(part.id, selected.id)}
                                                                        >
                                                                            {machines.map((machine, ind) => (
                                                                                <div
                                                                                    key={`my_select_o_${i}_${ind}_${part.id}`}
                                                                                    className={`cursor-pointer badge badge-${machine.color}`}
                                                                                    option={machine}
                                                                                >
                                                                                    {machine.display_name}
                                                                                </div>
                                                                            ))}

                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td>
                                                                        <Attachment
                                                                            hyperlink={part.hyperlink}/>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mb-5"></div>

                <DragDropContext onDragEnd={onDragEnd}>
                    {machines.map((machine, i) => (
                        <DroppableCustom key={`machine_${i}_key_${machine.id}`} droppableId={machine.id}>
                            {(provided) => (
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">{machine.display_name}</h4>
                                            <div className="d-flex" style={{gap: '0.5rem'}}>
                                                <div
                                                    className={`badge badge-outline-${machine.order.color}`}>
                                                    {machine.order.display_name}
                                                </div>
                                                <div
                                                    className={`badge badge-outline-${machine.order2.color}`}>
                                                    {machine.order2.display_name}
                                                </div>
                                            </div>
                                            <br/>

                                            <div className="table-responsive pb-4"
                                                // onDragOver={(e) => onDragOver(e, i)}
                                                // onDrop={(e) => onDrop(e, machine.id)}
                                            >
                                                <table className="table"
                                                       ref={provided.innerRef} {...provided.droppableProps}
                                                    // ref={el => (tableRefs.current[i] = el)}
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th style={{minWidth: "5%"}}>Lp.</th>
                                                        <th style={{minWidth: "280px"}}>Nazwa projektu</th>
                                                        <th style={{minWidth: "280px"}}>Pozycja<br/>Numer | Nazwa</th>
                                                        <th style={{minWidth: "280px"}}>Część<br/>Numer | Nazwa</th>
                                                        <th style={{minWidth: "20px"}}>Ilość</th>
                                                        <th style={{minWidth: "150px"}}>Status obróbki</th>
                                                        <th style={{minWidth: "10.5%"}}>Materiał<br/>zamówiony</th>
                                                        <th style={{minWidth: "10.5%"}}>Hartowanie<br/>Azotowanie</th>
                                                        <th style={{minWidth: "10.5%"}}>Priorytet</th>
                                                        <th style={{minWidth: "10.5%"}}>Stopień<br/>trudności</th>
                                                        <th style={{minWidth: "150px"}}>Materiał</th>
                                                        <th style={{minWidth: "10.5%"}}>Wymiary</th>
                                                        <th style={{minWidth: "10.5%"}}>Czas<br/>rozopczecia</th>
                                                        <th style={{minWidth: "10.5%"}}>Załącznik</th>
                                                        <th>Akcje</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {dataForMachine !== null && dataForMachine[i].map((data, j) => (
                                                        <Draggable key={data.position_parts.id.toString()}
                                                                   draggableId={data.position_parts.id.toString()}
                                                                   index={j}>
                                                            {(provided, snapshot) => (

                                                                <tr className={"hover"}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>{j + 1}</td>
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>
                                                                        <TextWithTooltip
                                                                            text={data.project_name}/>
                                                                    </td>
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>{data.position_number}<br/>
                                                                        <TextWithTooltip
                                                                            text={data.position_name}/>
                                                                    </td>
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>{data.position_parts.display_number}<br/><TextWithTooltip
                                                                        text={data.position_parts.display_name}/>
                                                                    </td>
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>{data.position_parts.quantity}</td>
                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Status CNC"}
                                                                            defaultValue={handleDefaultStatusCNC(data.position_parts.status_cnc)}
                                                                            onSelect={(selected) => handleChangeStatusCNC(data.position_parts.id, selected.id, machine.id)}
                                                                            isOutline={false}
                                                                        >
                                                                            {statuses
                                                                                .filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC)
                                                                                .map((status, ind) => (
                                                                                    <div
                                                                                        key={`my_select_machine_${machine.id}_cstatus_${data.position_parts.id}_${ind}`}
                                                                                        className={`cursor-pointer badge badge-${status.color}`}
                                                                                        option={status}
                                                                                    >
                                                                                        {status.display_name}
                                                                                    </div>
                                                                                ))}
                                                                        </CustomSelect>

                                                                    </td>
                                                                    <td className={"cursor-hand"}>
                                                                        <CustomSelect
                                                                            title={"Materiał zamówiony"}
                                                                            defaultValue={handleDefaultMaterialOrder(data.position_parts.material_order)}
                                                                            onSelect={(selected) => handleUpdateMachine(data.position_parts.id, 'material_order', selected.id)}
                                                                            customClass={"badge-sm"}
                                                                            isOutline={true}
                                                                        >
                                                                            <div
                                                                                className={`cursor-pointer badge badge-success`}
                                                                                option={{id: 'Na stanie'}}
                                                                            >
                                                                                Na
                                                                                stanie
                                                                            </div>
                                                                            <div
                                                                                className={`cursor-pointer badge badge-warning`}
                                                                                option={{id: 'Zamówiono'}}
                                                                            >
                                                                                Zamówiono
                                                                            </div>
                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td className={"cursor-hand"}>
                                                                        <div
                                                                            className={"row"}>
                                                                            <div
                                                                                className={"col-6"}>
                                                                                <CustomSelect
                                                                                    title={"Hartowanie"}
                                                                                    defaultValue={handleDefaultYesOrNo(data.position_parts.temper)}
                                                                                    onSelect={(selected) => handleUpdateMachine(data.position_parts.id, 'temper', selected.id)}
                                                                                    customClass={"badge-sm"}
                                                                                    clickable={false}
                                                                                    isOutline={true}
                                                                                >
                                                                                    {YesOrNo.map((yn) => (
                                                                                        <div
                                                                                            key={`my_select_machine_${machine.id}_c_h_${i}`}
                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                            option={yn}
                                                                                        >
                                                                                            {yn.display_name}
                                                                                        </div>
                                                                                    ))}
                                                                                </CustomSelect>
                                                                            </div>
                                                                            <div
                                                                                className={"col-6"}>
                                                                                <CustomSelect
                                                                                    title={"Azotowanie"}
                                                                                    defaultValue={handleDefaultYesOrNo(data.position_parts.azotize)}
                                                                                    onSelect={(selected) => handleUpdateMachine(data.position_parts.id, 'azotize', selected.id)}
                                                                                    customClass={"badge-sm"}
                                                                                    clickable={false}
                                                                                    isOutline={true}
                                                                                >
                                                                                    {YesOrNo.map((yn) => (
                                                                                        <div
                                                                                            key={`my_select_machine_${machine.id}_a_${i}`}
                                                                                            className={`cursor-pointer badge badge-${yn.color}`}
                                                                                            option={yn}
                                                                                        >
                                                                                            {yn.display_name}
                                                                                        </div>
                                                                                    ))}
                                                                                </CustomSelect>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Priorytet"}
                                                                            defaultValue={handleDefaultPriority(data.position_parts.priority)}
                                                                            onSelect={(selected) => handleUpdateMachine(data.position_parts.id, 'priority', selected.id)}
                                                                            isOutline={true}
                                                                        >
                                                                            {priorityStatus.map((p, ind) => (
                                                                                <div
                                                                                    key={`my_select_machine_${machine.id}_cpriority_${data.position_parts.id}_${ind}`}
                                                                                    className={`cursor-pointer badge badge-${p.color}`}
                                                                                    option={p}
                                                                                >
                                                                                    {p.display_name}
                                                                                </div>
                                                                            ))}
                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td>
                                                                        <CustomSelect
                                                                            title={"Stopień trudności"}
                                                                            defaultValue={handleDefaultComplicated(data.position_parts.complicated)}
                                                                            onSelect={(selected) => handleUpdateMachine(data.position_parts.id, 'complicated', selected.id)}
                                                                            isOutline={true}
                                                                        >
                                                                            {complicatedStatus.map((c, ind) => (
                                                                                <div
                                                                                    key={`my_select_machine_${machine.id}_c_complidated_${data.position_parts.id}_${ind}`}
                                                                                    className={`cursor-pointer badge badge-${c.color}`}
                                                                                    option={c}
                                                                                >
                                                                                    {c.display_name}
                                                                                </div>
                                                                            ))}
                                                                        </CustomSelect>
                                                                    </td>
                                                                    <td onClick={() => handleShowPartModalDetail(data.position_parts.id)}
                                                                        className={"cursor-hand"}>{data.position_parts.name_material}</td>
                                                                    <td
                                                                        className={"cursor-hand"}>
                                                                        <IconWithTooltip
                                                                            title="Wymiary"
                                                                            content={data.position_parts.dimensions}
                                                                            pathIcon={mdiMoveResizeVariant}
                                                                        />
                                                                    </td>
                                                                    <td className={"cursor-hand"}>
                                                                        <IconWithTooltip
                                                                            title="Czas rozpoczęcia"
                                                                            content={getCurrentDateTime(data.position_parts.time_start)}
                                                                            pathIcon={mdiClock}
                                                                        />
                                                                    </td>
                                                                    <td className={"cursor-hand"}>
                                                                        <Attachment
                                                                            hyperlink={data.position_parts.hyperlink}/>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-info btn-rounded btn-icon"
                                                                            title={"Cofnij do 'Zlecenia do wykonania'"}
                                                                            onClick={() => handleBackToBeDone(data.position_parts.id)}
                                                                        >
                                                                            <Icon
                                                                                path={mdiBackupRestore}
                                                                                size={0.7}/>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                        // </React.Fragment>
                                                    ))}
                                                    {provided.placeholder}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DroppableCustom>
                    ))}
                </DragDropContext>

                <div className="col-lg-12 mb-5"></div>

                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card pb-4">
                        <div className="card-body">
                            <h4 className="card-title">Zlecenia zakończone</h4>

                            <div className={`table-responsive ${finishProjectPart.length ? 'scroll-table' : ''}`}>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th style={{width: "5%"}}>Lp.</th>
                                        <th style={{width: "9.5%"}}>Nazwa projektu</th>
                                        <th style={{width: "9.5%"}}>Pozycja<br/>Numer | Nazwa</th>
                                        <th style={{width: "9.5%"}}>Część<br/>Numer | Nazwa</th>
                                        <th style={{width: "12%"}}>Maszyna<br/>Operator<br/>Programista</th>
                                        <th style={{width: "6.5%"}}>Ilość</th>
                                        <th style={{width: "9.5%"}}>Materiał</th>
                                        <th style={{width: "9.5%"}}>Czas<br/>rozopczecia</th>
                                        <th style={{width: "9.5%"}}>Czas<br/>zakończenia</th>
                                        <th style={{width: "10%"}}>Stopień<br/>skomplikowania</th>
                                        <th style={{width: "9.5%"}}>Załącznik</th>
                                        <th>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {finishProjectPart.map((f, j) => (
                                        <tr key={`f_${f.position_parts.id}`} className={'hover'}>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{j + 1}</td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{f.project_name}</td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{f.position_number}<br/>{f.position_name}
                                            </td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>
                                                {f.position_parts.display_number}<br/>{f.position_parts.display_name}
                                            </td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{!f.position_parts.performed ? 'Brak danych' : f.position_parts.performed}</td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{f.position_parts.quantity}</td>
                                            <td onClick={() => handleShowPartModalDetail(f.position_parts.id)}>{f.position_parts.name_material}</td>
                                            <td>
                                                <IconWithTooltip
                                                    title="Czas rozpoczęcia"
                                                    content={getCurrentDateTime(f.position_parts.time_start)}
                                                    pathIcon={mdiClock}
                                                />
                                            </td>
                                            <td>
                                                <IconWithTooltip
                                                    title="Czas zakończenia"
                                                    content={getCurrentDateTime(f.position_parts.time_end)}
                                                    pathIcon={mdiClock}
                                                />
                                            </td>
                                            <td>{handleComplicated(f.position_parts.complicated, -1, false)}</td>
                                            <td><Attachment hyperlink={f.position_parts.hyperlink}/></td>
                                            <td>
                                                <button type="button"
                                                        className="btn btn-info btn-rounded btn-icon"
                                                        title={"Cofnij do 'Maszyny'"}
                                                        onClick={() => handleBackToMachine(f.position_parts.id)}
                                                >
                                                    <Icon path={mdiBackupRestore} size={0.7}/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PartModalDetail part_id={partId} setShow={setPartModalDetailShow} show={partModalDetailShow}
                             statuses={statuses}/>
        </>
    )
}


export default CNCMachining