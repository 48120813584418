import {createContext, useContext, useEffect, useState} from "react";
import useAxios from "../api/useAxios";
import {fetchCurrentUser} from "../api/ApiUser";
import {AuthContext} from "./AuthContext";

const defaultUserContext = {
    user: null,
}

export const UserContext = createContext(defaultUserContext);

const UserProvider = ({children}) => {
    const axiosInstance = useAxios();
    const {authState} = useContext(AuthContext);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(authState.isAuthenticated) {
            fetchCurrentUser(axiosInstance).then((response) => {
                if (response) {
                    setUser(response.data);
                }
            })
        }
    }, [axiosInstance, authState]);

    return (
        <UserContext.Provider value={{user}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;