export const fetchConfigureList = async (axiosInstance, _type) => {
    try {
        const response = await axiosInstance.get(`/configures/${_type}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchConfigure = async (axiosInstance, _type, id) => {
    try {
        const response = await axiosInstance.get(`/configure/${_type}/${id}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchConfigureCreate = async (axiosInstance, _type, data) => {
    try {
        const response = await axiosInstance.post(`/configure-create/${_type}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchConfigureUpdate = async (axiosInstance, _type, id, data) => {
    try {
        const response = await axiosInstance.put(`/configure-update/${_type}/${id}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchConfigureRemove = async (axiosInstance, _type, id) => {
    try {
        const response = await axiosInstance.delete(`/configure-delete/${_type}/${id}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}