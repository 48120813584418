import React, {useEffect} from 'react';
import useAxios from "../../../api/useAxios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {statusColor} from "../../../helpers/Status";
import {
    fetchConfigure,
    fetchConfigureCreate,
    fetchConfigureList, fetchConfigureRemove,
    fetchConfigureUpdate
} from "../../../api/ApiConfigureOther";

const ConfigureForm = ({_type, uri, name, back_title, isShow, showLabel, configure_type_id, setRefreshSM}) => {
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const {id} = useParams();

    const [messages, setMessages] = React.useState({message: '', status: ''});

    const [orders, setOrders] = React.useState([]);

    const [inputs, setInputs] = React.useState({
        display_name: '',
        color: statusColor[0].id,
    });
    const [dynamicOrderId, setDynamicOrderId] = React.useState(null);
    const [isEditableFinish, setIsEditableFinish] = React.useState(false);

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    const handleCreateOrder = async (e) => {
        e.preventDefault();

        let _input = inputs;
        if(dynamicOrderId !== null) {
            if (configure_type_id === 1) {
                _input = {
                    ...inputs,
                    id_programmer: dynamicOrderId
                }
            } else if (configure_type_id === 2) {
                _input = {
                    ...inputs,
                    id_operator: dynamicOrderId
                }
            }
        }

        const response = await fetchConfigureCreate(axiosInstance, configure_type_id + 1, _input);

        if (response.status === 'success') {
            setRefreshSM(true);
            navigate(`/${uri}`, {state: {message: response.message, status: 'success'}});
        } else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const handleUpdateOrder = async (e) => {
        e.preventDefault();

        let _input = inputs;
        if(dynamicOrderId !== null) {
            if (configure_type_id === 1) {
                _input = {
                    ...inputs,
                    id_programmer: dynamicOrderId
                }
            } else if (configure_type_id === 2) {
                _input = {
                    ...inputs,
                    id_operator: dynamicOrderId
                }
            }
        }

        const response = await fetchConfigureUpdate(axiosInstance, configure_type_id +1, id, _input);

        if(response.status === 'success') {
            setRefreshSM(true);
            navigate(`/${uri}`, {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    useEffect(() => {
        if(_type === 'edit') {
            getConfigureEdit(id);
        }

        async function getConfigureEdit(id) {
            const response = await fetchConfigure(axiosInstance, configure_type_id + 1, id);

            if(response.status === 'success') {
                setInputs({
                    display_name: response.data.display_name,
                    color: response.data.color,
                });
                if(configure_type_id > 0) {
                    console.log(configure_type_id === 1 ? response.data.id_programmer : response.data.id_operator)
                    setDynamicOrderId(configure_type_id === 1 ? response.data.id_programmer : response.data.id_operator);
                }
            }
            else {
                navigate(`/${uri}`, {state: {message: response.message, status: 'danger'}});
            }
        }
    }, [_type, axiosInstance, configure_type_id, isEditableFinish])

    useEffect(() => {
        if (configure_type_id === 0) return;

        getConfigure();

        async function getConfigure() {
            let response = {status: 'error', message: 'Nie wybrano konfiguracji'};
            if (configure_type_id === 1) {
                response = await fetchConfigureList(axiosInstance, configure_type_id);
            } else if (configure_type_id === 2) {
                response = await fetchConfigureList(axiosInstance, configure_type_id);
            }

            if (response.status === 'success') {
                const result = response.data;

                if(result.length) {
                    setOrders(result);
                    if(_type === 'create') {
                        setDynamicOrderId(result[0].id);
                    }
                    setIsEditableFinish(true);
                }
            } else {
                setMessages({
                    message: response.message,
                    status: 'danger'
                });
            }
        }
    }, [axiosInstance, configure_type_id]);

    const handleDynamicValueOrder = (value) => {
        console.log(value);
        setDynamicOrderId(value);
    }

    if(_type === 'edit' && inputs.display_name === "" && orders.length) {
        return ""
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{_type === 'create' ? `Dodaj ${name}` : `Edytuj ${name}`} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={uri}>{back_title}</Link></li>
                        <li className="breadcrumb-item active"
                            aria-current="page">{_type === 'create' ? `Dodaj ${name}` : `Edytuj ${name}`}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages.message &&
                                <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}

                            <form className={"forms-sample"}
                                  onSubmit={_type === 'create' ? handleCreateOrder : handleUpdateOrder}>
                                {isShow &&
                                    <div className="form-group">
                                        <label htmlFor="InputX">{showLabel}</label>
                                        <select className="form-control" id="InputX"
                                                
                                                defaultValue={dynamicOrderId}
                                                onChange={(e) => handleDynamicValueOrder(e.target.value)}
                                        >
                                            {orders.map((order) => (
                                                <option key={order.id} value={order.id}>{order.display_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_name">Wyświetlana nazwa</label>
                                    <input type="text" className="form-control" id="InputDisplay_name"
                                           value={inputs.display_name}
                                           onChange={(e) => handleChangeInput('display_name', e.target.value)}
                                           placeholder="Wyświetlana nazwa"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="InputBg_Color">Kolor</label>
                                    <select className="form-control" id="InputBg_Color"
                                            
                                            onChange={(e) => handleChangeInput('color', e.target.value)}
                                            defaultValue={inputs.color}
                                    >
                                        {statusColor.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <br/><br/>
                                <button type="submit"
                                        className="btn btn-primary mr-2">{_type === 'create' ? 'Utwórz' : 'Edytuj'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfigureForm;