export const fetchRoleList = async (axiosInstance) => {
    try {
        const response = await axiosInstance.get('/roles');
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

export const fetchRole = async (axiosInstance, roleId) => {
    try {
        const response = await axiosInstance.get(`/roles/${roleId}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchRoleCreate = async (axiosInstance, data) => {
    try {
        const response = await axiosInstance.post('/role/create', data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchRoleUpdate = async (axiosInstance, roleId, data) => {
    try {
        const response = await axiosInstance.put(`/role/update/${roleId}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchRoleDelete = async (axiosInstance, roleId) => {
    try {
        const response = await axiosInstance.delete(`/role/delete/${roleId}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}