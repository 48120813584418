import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2024</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Created by <a href="#">Dawid W.</a> | Build 1.0</span>
            </div>
        </footer>
    );
}

export default Footer;