import React from 'react';
import {Button, Modal} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";

const ModalAreYouSure = ({show, setShow, btn_name_accept, handleAccept, modalData}) => {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>{modalData.title}</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                {modalData.body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => setShow(false)}>
                    Anuluj
                </Button>
                <Button variant="primary" onClick={handleAccept}>
                    {btn_name_accept}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAreYouSure;