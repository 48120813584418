import React, { useState } from 'react';

const TextWithTooltip = ({ text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const getShortText = (text) => {
        if (text.length > 30) {
            return text.substring(0, 28) + '...';
        }
        return text;
    };

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span>{getShortText(text)}</span>

            {/* Pokaż tooltip na hover */}
            {isHovered && text.length > 30 && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '5px',
                        borderRadius: '5px',
                        whiteSpace: 'nowrap',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 10,
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default TextWithTooltip;