import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import Login from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Roles from "./pages/users/Roles";
import Projects from "./pages/projects/Projects";
import UserForm from "./pages/users/UserForm";
import RoleForm from "./pages/users/RoleForm";
import Statuses from "./pages/configure/status/Statuses";
import StatusForm from "./pages/configure/status/StatusForm";
import ProjectsForm from "./pages/projects/ProjectsForm";
import Configure from "./pages/configure/other/Configure";
import ConfigureForm from "./pages/configure/other/ConfigureForm";
import CNCMachining from "./pages/cnc-machining/CNCMachining";
import PartOrdersProject from "./pages/projects/PartOrdersProject";


function AppRouter({statuses, machines, setRefreshSM}) {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/" element={<Navigate to="/dashboard"/>}/>
            <Route path="/dashboard" element={<Dashboard statuses={statuses} machines={machines} />}/>

            <Route path="/users" element={<Users/>}/>
            <Route path="/users/create" element={<UserForm _type={"create"}/>}/>
            <Route path="/users/edit/:id" element={<UserForm _type={"edit"}/>}/>

            <Route path="/roles" element={<Roles/>}/>
            <Route path="/roles/create" element={<RoleForm _type={"create"}/>}/>
            <Route path="/roles/edit/:id" element={<RoleForm _type={"edit"}/>}/>

            {/*<Route path="/projects/external-orders"*/}
            {/*       element={*/}
            {/*           <Projects*/}
            {/*               name={"zewnętrznych"}*/}
            {/*               btn_name={"Dodaj projekt zewnętrzny"}*/}
            {/*               type_project={0}*/}
            {/*               uri={'external-orders'}*/}
            {/*               statuses={statuses}*/}
            {/*           />*/}
            {/*       }/>*/}
            {/*<Route path="/projects/external-orders/create"*/}
            {/*       element={*/}
            {/*           <ProjectsForm*/}
            {/*               name={"zewnętrzny"}*/}
            {/*               type_project={0}*/}
            {/*               _type="create"*/}
            {/*               uri={'external-orders'}*/}
            {/*               defaultName={""}*/}
            {/*           />*/}
            {/*       }/>*/}
            {/*<Route path="/projects/external-orders/edit/:id"*/}
            {/*       element={*/}
            {/*           <ProjectsForm*/}
            {/*               name={"zewnętrzny"}*/}
            {/*               type_project={0}*/}
            {/*               _type="edit"*/}
            {/*               uri={'external-orders'}*/}
            {/*               defaultName={""}*/}
            {/*           />*/}
            {/*       }/>*/}
            <Route path={"/projects/part-orders"}
                   element={<PartOrdersProject statuses={statuses} />}
            />
            <Route path="/projects/internal-orders"
                   element={
                       <Projects
                           name={"wewnętrznych"}
                           btn_name={"Dodaj projekt wewnętrzny"}
                           type_project={1}
                           uri={'internal-orders'}
                           statuses={statuses}
                       />
                   }/>
            <Route path="/projects/internal-orders/create"
                   element={
                       <ProjectsForm
                           name={"wewnętrzny"}
                           type_project={1}
                           _type="create"
                           uri={'internal-orders'}
                           defaultName={"ZABI"}
                       />
                   }/>
            <Route path="/projects/internal-orders/edit/:id"
                   element={
                       <ProjectsForm
                           name={"wewnętrzny"}
                           type_project={1}
                           _type="edit"
                           uri={'internal-orders'}
                           defaultName={""}
                       />
                   }/>

            <Route path="/configure-status" element={<Statuses setRefreshSM={setRefreshSM}/>}/>
            <Route path="/configure-status/create" element={<StatusForm _type={"create"} setRefreshSM={setRefreshSM}/>}/>
            <Route path="/configure-status/edit/:id" element={<StatusForm _type={"edit"} setRefreshSM={setRefreshSM}/>}/>

            <Route path="/configure-programmer"
                   element={
                       <Configure
                           name={"Lista programistów"}
                           configure_type={'programmer'}
                           table_name={""}
                           uri={'configure-programmer'}
                           btn_add={"Dodaj programistę"}
                       />}
            />
            <Route path="/configure-operator"
                   element={
                       <Configure
                           name={"Lista operatorów"}
                           configure_type={'operator'}
                           table_name={"Programista"}
                           uri={'configure-operator'}
                           btn_add={"Dodaj oberatora"}
                       />}
            />
            <Route path="/configure-machine"
                   element={
                       <Configure
                           name={"Lista maszyn"}
                           configure_type={'machine'}
                           table_name={'Operator'}
                           uri={'configure-machine'}
                           btn_add={"Dodaj maszynę"}
                       />}
            />

            <Route path="/configure-programmer/create"
                   element={
                       <ConfigureForm
                           _type={"create"}
                           name={"programiste"}
                           uri={'configure-programmer'}
                           back_title={"Lista programistów"}
                           isShow={false}
                           showLabel={""}
                           configure_type_id={0}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/configure-operator/create"
                   element={
                       <ConfigureForm
                           _type={"create"}
                           name={"operatora"}
                           uri={'configure-operator'}
                           back_title={"Lista operatorów"}
                           isShow={true}
                           showLabel={"Wybierz Programistę"}
                           configure_type_id={1}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/configure-machine/create"
                   element={
                       <ConfigureForm
                           _type={"create"}
                           name={"maszyne"}
                           uri={'configure-machine'}
                           back_title={"Lista maszyn"}
                           isShow={true}
                           showLabel={"Wybierz Operatora"}
                           configure_type_id={2}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/configure-programmer/edit/:id"
                   element={
                       <ConfigureForm
                           _type={"edit"}
                           name={"programiste"}
                           uri={'configure-programmer'}
                           back_title={"Lista programistów"}
                           isShow={false}
                           showLabel={""}
                           configure_type_id={0}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/configure-operator/edit/:id"
                   element={
                       <ConfigureForm
                           _type={"edit"}
                           name={"operatora"}
                           uri={'configure-operator'}
                           back_title={"Lista operatorów"}
                           isShow={true}
                           showLabel={"Wybierz Programistę"}
                           configure_type_id={1}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/configure-machine/edit/:id"
                   element={
                       <ConfigureForm
                           _type={"edit"}
                           name={"maszyne"}
                           uri={'configure-machine'}
                           back_title={"Lista maszyn"}
                           isShow={true}
                           showLabel={"Wybierz Operatora"}
                           configure_type_id={2}
                           setRefreshSM={setRefreshSM}
                       />}
            />

            <Route path="/cnc-machining" element={<CNCMachining statuses={statuses} machines={machines} />} />
        </Routes>
    )
}

export default AppRouter;