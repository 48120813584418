import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {complicatedStatus, TYPE_STATUS_CNC} from "../helpers/Status";
import {fetchProjectCNCPerMachineForNoStatus} from "../api/ApiProject";
import useAxios from "../api/useAxios";
import Attachment from "./Attachment";
import {mdiClose} from "@mdi/js";
import Icon from "@mdi/react";

const DashboardModalTableProjectComplicated = ({ data, show, setShow, statuses }) => {
    const axiosInstance = useAxios();

    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(show) {
            getDataForMachine();
        }

        async function getDataForMachine() {
            setLoading(false);
            const status_cnc = statuses.find(s => s.kind_status === 'status_end' && parseInt(s.type_status) === TYPE_STATUS_CNC);

            const response = await fetchProjectCNCPerMachineForNoStatus(axiosInstance, status_cnc.id, data.machine_id);

            if(response.status === 'success') {
                setParts(response.projects);
                setLoading(true);
            }
        }

    }, [show])

    const handleStatusCNC = (status_cnc) => {
        const statusesCNC = statuses.filter(s => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const status = statusesCNC.find((s) => parseInt(s.id) === parseInt(status_cnc));

        if (!status) {
            return (
                <div
                    className={`badge badge-${statusesCNC[0].color}`}>
                    {statusesCNC[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-${status.color}`}>
                {status.display_name}
            </div>
        )
    }

    const handlePriority = (priority_id) => {
        const priority = complicatedStatus.find(p => p.id === parseInt(priority_id));

        if (!priority) {
            return (
                <div
                    className={`badge badge-outline-${complicatedStatus[0].color}`}>
                    {complicatedStatus[0].display_name}
                </div>
            )
        }

        return (
            <div
                className={`badge badge-outline-${priority.color}`}>
                {priority.display_name}
            </div>
        )
    }

    if(!loading) {
        return '';
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} className={"custom-modal-style"}>
            <Modal.Header>
                <Modal.Title>Informacje o projektach</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                <p>Stopnia skomplikowania: {complicatedStatus.find(c => c.id === data.complicated_id).display_name}</p>
                <p>Maszyna: {data.machine_name}</p>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{width: "5%"}}>Lp.</th>
                            <th style={{width: "10.5%"}}>Nazwa projektu</th>
                            <th style={{width: "10.5%"}}>Pozycja<br/>Numer | Nazwa</th>
                            <th style={{width: "10.5%"}}>Część<br/>Numer | Nazwa</th>
                            <th style={{width: "6.5%"}}>Ilość</th>
                            <th style={{width: "13.5%"}}>Status obróbki</th>
                            <th style={{width: "10.5%"}}>Materiał<br/>zamówiony</th>
                            <th style={{width: "10.5%"}}>Materiał</th>
                            <th style={{width: "10.5%"}}>Hartowanie<br/>Azotowanie</th>
                            <th style={{width: "10.5%"}}>Wymiary</th>
                            <th style={{width: "10.5%"}}>Priorytet</th>
                            <th style={{width: "10.5%"}}>Załącznik</th>
                        </tr>
                        </thead>
                        <tbody>
                        {parts
                            .filter(part => parseInt(part.position_parts.complicated) === data.complicated_id)
                            .map((part, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{part.project_name}</td>
                                    <td>{part.position_number}<br/>{part.position_name}
                                    </td>
                                    <td>
                                        {part.position_parts.display_number}<br/>{part.position_parts.display_name}
                                    </td>
                                    <td>{part.position_parts.quantity}</td>
                                    <td>{handleStatusCNC(part.position_parts.status_cnc)}</td>
                                    <td>{part.position_parts.material_order ? "TAK" : "NIE"}</td>
                                    <td>{part.position_parts.name_material}</td>
                                    <td>
                                        {part.position_parts.temper === "0" ?
                                            <span title={"Hartowanie"}>NIE</span> : <span
                                                title={"Hartowanie"}>TAK</span>} | {part.position_parts.azotize === "0" ?
                                        <span title={"Azotowanie"}>NIE</span> :
                                        <span title={"Azotowanie"}>TAK</span>}
                                    </td>
                                    <td>{part.position_parts.dimensions}</td>
                                    <td>{handlePriority(part.position_parts.priority)}</td>
                                    <td><Attachment hyperlink={part.position_parts.hyperlink} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShow(false)}>Zamknij</button>
            </Modal.Footer>
        </Modal>
)
}

export default DashboardModalTableProjectComplicated;