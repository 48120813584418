import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import Icon
    from "@mdi/react";
import {
    mdiBackupRestore,
    mdiMoveResizeVariant,
    mdiPlus
} from "@mdi/js";
import {
    fetchProjectDetailDeletePart,
    fetchProjectDetailPositionPartUpdateStatus,
    fetchProjectPositionPartUpdate,
    fetchProjectsList
} from "../../api/ApiProject";
import useAxios
    from "../../api/useAxios";
import ProjectDetailModalAddPart
    from "../../components/projects/ModalAddPartOrdersProject";
import CustomSelect
    from "../../components/CustomSelect";
import {
    TYPE_MATERIAL_ORDER_ID,
    TYPE_STATUS_CNC,
    TYPE_STATUS_PROJECT_PART_ID,
    YesOrNo
} from "../../helpers/Status";
import IconWithTooltip
    from "../../components/projects/IconWithTooltip";
import Attachment
    from "../../components/Attachment";
import {
    Dropdown
} from "react-bootstrap";
import {findLargestNumber} from "../../helpers/projectHelper";
import PartModalDetail from "../../components/PartModalDetail";
import TextWithTooltip from "../../components/TextWitchTooltip";

const PartOrdersProject = ({statuses}) => {
    const axiosInstance = useAxios();
    const [projects, setProjects] = useState([]);
    const [projectsFinish, setProjectsFinish] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const hasRunGetProject = useRef(false);

    const [showModal, setShowModal] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false)

    const [partModalDetailShow, setPartModalDetailShow] = useState(false);
    const [partId, setPartId] = useState(null);

    const [dataModal, setDataModal] = useState({
        title: '',
        isEdit: false,
        idPart: null
    });

    useEffect(() => {
        if ((!projects.length && !hasRunGetProject.current) || !showModal || isRefresh) {
            projectsList();
        }

        async function projectsList() {
            const response = await fetchProjectsList(axiosInstance, -1);

            const updatedProjectsFinish = [];
            const updatedProjects = [];

            const status_cnc = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_CNC);
            const status = status_cnc.find((s) => s.kind_status === 'status_end');

            if (response.status === 'success') {
                setAllProjects(response.projects);

                response.projects.forEach((project) => {

                    project.project_positions.forEach((position) => {
                        position.project_position_part.forEach((part) => {
                            const newObj = {
                                id_project: project.id_project,
                                project_name: project.project_name,
                                project_client_name: project.project_client_name,
                                project_created_at: project.project_created_at,
                                project_positions: [
                                    {
                                        id_position: position.id_position,
                                        display_name: position.display_name,
                                        display_number: position.display_number,
                                        created_at: position.created_at,
                                        type: position.type,
                                        user: position.user,
                                        project_position_part: [part]
                                    }
                                ]
                            }

                            if (parseInt(part.status_cnc) === parseInt(status.id)) {
                                updatedProjectsFinish.push(newObj);
                            } else {
                                updatedProjects.push(newObj);
                            }
                        });
                    });
                });

                setProjectsFinish(updatedProjectsFinish);
                setProjects(updatedProjects);
            }

            hasRunGetProject.current = true;
            setIsRefresh(false);
        }

    }, [axiosInstance, hasRunGetProject, showModal, isRefresh]);

    const GetStatusForPart = (IdStatus) => {
        const status = statuses.find((s) => parseInt(s.id) === parseInt(IdStatus));

        if (!status) {
            return {
                color: 'outline-dark',
                display_name: 'Brak statusu'
            }
        }

        return {
            color: `outline-${status.color}`,
            display_name: status.display_name
        }
    }
    const handleDefaultMaterialOrder = (id) => {
        const materialOrder = statuses.find(mo => parseInt(mo.id) === parseInt(id));

        if (!materialOrder) {
            return {
                color: 'light',
                display_name: 'Brak'
            }
        }

        return materialOrder;
    }

    const handleChangeCustomSelectPart = async (id_position_part, name, id) => {
        const statusCNC = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_CNC);
        const statusNormal = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);

        const status_cnc = statusCNC.find((s) => s.kind_status === 'status_end');
        const status = statusNormal.find((s) => parseInt(s.id) === parseInt(id));

        const updatedProject = projects.map((project) => ({
            ...project,
            project_positions: project.project_positions.map((position) => ({
                ...position,
                project_position_part: position.project_position_part.map((part) => {
                    if (parseInt(part.id_position_part) === parseInt(id_position_part)) {
                        if (name === 'status') {
                            return {
                                ...part,
                                [name]: id,
                                status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id
                            };
                        } else {
                            return {
                                ...part,
                                [name]: id
                            };
                        }
                    }
                    return part;
                }),
            })),
        }));

        const allPartsCount = updatedProject.map((project) => {
            const allPartsCount = project.project_positions.reduce((total, position) => {
                return total + position.project_position_part.length;
            }, 0);

            const statusCNCEndCount = project.project_positions.reduce((count, position) => {
                return count + position.project_position_part.filter(part => part.status_cnc === status_cnc.id).length;
            }, 0);

            return {
                project,
                allPartsCount,
                statusCNCEndCount
            };
        });

        const newProjectsFinish = [];
        const newProjects = [];

        allPartsCount.forEach((p) => {
            if (p.allPartsCount === p.statusCNCEndCount) {
                newProjectsFinish.push(p.project);
            } else {
                newProjects.push(p.project);
            }
        });

        setProjectsFinish(prev => [...newProjectsFinish, ...prev]);
        setProjects(newProjects);


        if (name === 'status') {
            await fetchProjectPositionPartUpdate(axiosInstance, id_position_part, {
                [name]: id,
                status_cnc: status.kind_status !== 'status_end' ? 0 : status_cnc.id,
                id_machine: 0
            });
        } else {
            await fetchProjectPositionPartUpdate(axiosInstance, id_position_part, {[name]: id});
        }
    }

    const handleUpdateMaterial = async (idPart, materialOrder) => {
        setProjects((prevData) =>
            prevData.map((project) => ({
                ...project,
                project_positions: project.project_positions.map((position) => ({
                    ...position,
                    project_position_part: position.project_position_part.map((part) => {
                        if (parseInt(part.id_position_part) === parseInt(idPart)) {
                            return {
                                ...part,
                                material_order: materialOrder
                            };
                        }
                        return part;
                    }),
                })),
            }))
        );

        await fetchProjectPositionPartUpdate(axiosInstance, idPart, {material_order: materialOrder});
    }

    const handleRemovePart = async (idPart) => {
        const response = await fetchProjectDetailDeletePart(axiosInstance, idPart);

        if (response.status === 'success') {
            setIsRefresh(true);
        }
    }

    const handleAddPart = () => {
        setDataModal({
            title: 'Dodaj część',
            isEdit: false,
            idPart: null
        });
        setShowModal(true);
    }

    const handleEditPart = (idPart) => {
        setDataModal({
            title: 'Edytuj część',
            isEdit: true,
            idPart: idPart
        });
        setShowModal(true);
    }

    const GetStatusCNCForPart = (IdStatus) => {
        const status = statuses.find((s) => parseInt(s.id) === parseInt(IdStatus));

        if (!status) {
            return {
                color: 'dark',
                display_name: 'Nie przypisano'
            }
        }

        return {
            color: `${status.color}`,
            display_name: status.display_name
        }
    }

    const handleDefaultYesOrNo = (id) => {
        const yn = YesOrNo.find(o => o.id === parseInt(id));

        if (!yn) {
            return {
                color: YesOrNo[0].color,
                display_name: YesOrNo[0].display_name
            }
        }

        return yn;
    }

    const handleBack = async (id_position_part) => {
        const statusNormal = statuses.filter((s) => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID);

        const response = await fetchProjectPositionPartUpdate(axiosInstance, id_position_part, {
            status: statusNormal[0].id,
            status_cnc: 0,
            id_machine: 0
        });

        if (response.status === 'success') {
            setIsRefresh(true);
        }
    }

    const handleShowPartModalDetail = (part_id) => {
        setPartId(part_id);
        setPartModalDetailShow(true);
    }

    return <>
        <div
            className="page-header">
            <h3 className="page-title"> Lista
                części</h3>
            <nav
                aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Projekty</li>
                </ol>
            </nav>
        </div>

        <div
            className="row">
            <div
                className="col-lg-12 grid-margin stretch-card">
                <div
                    className="card">
                    <div
                        className="card-body">

                        <div
                            className="float-right">
                            <button
                                type="button"
                                onClick={() => handleAddPart()}
                                className="btn btn-info btn-icon-text">
                                <Icon
                                    path={mdiPlus}
                                    size={0.7}/> Dodaj
                                część
                            </button>
                        </div>

                        <div
                            className="table-responsive">
                            <table
                                className="table mb-5 mt-1">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>Lp.</th>
                                    <th style={{width: "13.5%"}}>Część<br/>Numer
                                        |
                                        Nazwa
                                    </th>
                                    <th style={{width: "10.5%"}}>Klient</th>
                                    <th style={{width: "13.5%"}}>Ilość</th>
                                    <th style={{width: "13.5%"}}>Status</th>
                                    <th style={{width: "13.5%"}}>Status
                                        CNC
                                    </th>
                                    <th style={{minWidth: "110px"}}>Hartować<br/>Azotować
                                    </th>
                                    <th style={{width: "13.5%"}}>Materiał<br/>zamówiony
                                    </th>
                                    <th style={{width: "13.5%"}}>Materiał</th>
                                    <th style={{width: "13.5%"}}>Wymiary</th>
                                    <th style={{width: "13.5%"}}>Załącznik</th>
                                    <th style={{width: "13.5%"}}>Opis</th>
                                    <th>Akcje</th>
                                </tr>
                                </thead>
                                <tbody>
                                {projects.map((project, pindex) => (
                                    project.project_positions.map((position, ppindex) => (
                                        position.project_position_part.map((part, index) => (
                                            <tr className={'hover'}
                                                key={`part_orders_project_${index}`}>
                                                <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{pindex + ppindex + index + 1}</td>
                                                <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.display_number}<br/>{part.display_name}
                                                </td>
                                                <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{project.project_client_name}</td>
                                                <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.quantity}</td>
                                                <td>
                                                    <CustomSelect
                                                        defaultValue={GetStatusForPart(part.status)}
                                                        onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'status', selected.id)}
                                                    >
                                                        {statuses
                                                            .filter(s => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID)
                                                            .map((option, i) => (
                                                                <div
                                                                    key={`my_select_o_${i}`}
                                                                    className={`cursor-pointer badge badge-${option.color}`}
                                                                    option={option}
                                                                >
                                                                    {option.display_name}
                                                                </div>
                                                            ))}
                                                    </CustomSelect>
                                                </td>
                                                <td>
                                                    <CustomSelect
                                                        defaultValue={GetStatusCNCForPart(part.status_cnc)}
                                                        clickable={false}
                                                    ></CustomSelect>
                                                </td>
                                                <td>
                                                    <div
                                                        className={"row"}>
                                                        <div
                                                            className={"col-6"}>
                                                            <CustomSelect
                                                                title={"Hartowanie"}
                                                                defaultValue={handleDefaultYesOrNo(part.temper)}
                                                                onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'temper', selected.id)}
                                                                customClass={"badge-sm"}
                                                                isOutline={true}
                                                            >
                                                                {YesOrNo.map((yn, index) => (
                                                                    <div
                                                                        key={`my_select_machine_${part.id_position_part}_c_h_${index}`}
                                                                        className={`cursor-pointer badge badge-${yn.color}`}
                                                                        option={yn}
                                                                    >
                                                                        {yn.display_name}
                                                                    </div>
                                                                ))}
                                                            </CustomSelect>
                                                        </div>
                                                        <div
                                                            className={"col-6"}>
                                                            <CustomSelect
                                                                title={"Azotowanie"}
                                                                defaultValue={handleDefaultYesOrNo(part.azotize)}
                                                                onSelect={(selected) => handleChangeCustomSelectPart(part.id_position_part, 'azotize', selected.id)}
                                                                customClass={"badge-sm"}
                                                                isOutline={true}
                                                            >
                                                                {YesOrNo.map((yn, index) => (
                                                                    <div
                                                                        key={`my_select_machine_${part.id_position_part}_a_${index}`}
                                                                        className={`cursor-pointer badge badge-${yn.color}`}
                                                                        option={yn}
                                                                    >
                                                                        {yn.display_name}
                                                                    </div>
                                                                ))}
                                                            </CustomSelect>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>
                                                    <CustomSelect
                                                        title={"Materiał zamówiony"}
                                                        defaultValue={handleDefaultMaterialOrder(part.material_order)}
                                                        onSelect={(selected) => handleUpdateMaterial(part.id_position_part,  selected.id)}
                                                        customClass={"badge-sm"}
                                                        isOutline={true}
                                                    >
                                                        {statuses
                                                            .filter(mo => parseInt(mo.type_status) === TYPE_MATERIAL_ORDER_ID)
                                                            .map(mom => (
                                                                <div
                                                                    className={`cursor-pointer badge badge-${mom.color}`}
                                                                    option={mom}
                                                                >{mom.display_name}
                                                                </div>
                                                            ))
                                                        }
                                                    </CustomSelect>
                                                </td>
                                                <td onClick={() => handleShowPartModalDetail(part.id_position_part)}>{part.name_material}</td>
                                                <td>
                                                    <IconWithTooltip
                                                        title="Wymiary"
                                                        content={part.dimensions}
                                                        pathIcon={mdiMoveResizeVariant}
                                                    />
                                                </td>
                                                <td>
                                                    <Attachment
                                                        hyperlink={part.hyperlink}/>
                                                </td>
                                                <td>
                                                    {part.description === "" ? "Brak" : <TextWithTooltip
                                                        text={part.description}/>}
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="btn btn-primary"
                                                            id="dropdownMenuButton1">
                                                            Wybierz
                                                            akcje
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => handleEditPart(part.id_position_part)}>Edytuj</Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => handleRemovePart(part.id_position_part)}>Usuń</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                    ))
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            className="row">
            <div
                className="col-lg-12 grid-margin stretch-card">
                <div
                    className="card">
                    <div
                        className="card-body">
                        <div
                            className="card-title">Projekty
                            Zakończone
                        </div>

                        <div
                            className={`table-responsive ${projectsFinish.length ? 'scroll-table' : ''}`}>
                            <table
                                className="table mb-5 mt-1">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>Lp.</th>
                                    <th style={{width: "13.5%"}}>Część<br/>Numer
                                        |
                                        Nazwa
                                    </th>
                                    <th style={{width: "10.5%"}}>Klient</th>
                                    <th style={{width: "13.5%"}}>Ilość</th>
                                    <th style={{width: "13.5%"}}>Status</th>
                                    <th style={{width: "13.5%"}}>Status
                                        CNC
                                    </th>
                                    <th style={{width: "13.5%"}}>Materiał</th>
                                    <th style={{width: "13.5%"}}>Wymiary</th>
                                    <th style={{width: "13.5%"}}>Załącznik</th>
                                    <th>Akcje</th>
                                </tr>
                                </thead>
                                <tbody>
                                {projectsFinish.map((project, pindex) => (
                                    project.project_positions.map((position, ppindex) => (
                                        position.project_position_part.map((part, index) => (
                                            <tr className={'hover'}
                                                key={`part_orders_project_${index}`}>
                                                <td>{pindex + ppindex + index + 1}</td>
                                                <td>{part.display_number}<br/>{part.display_name}
                                                </td>
                                                <td>{project.project_client_name}</td>
                                                <td>{part.quantity}</td>
                                                <td>
                                                    <CustomSelect
                                                        defaultValue={GetStatusForPart(part.status)}
                                                        clickable={false}
                                                    >
                                                        {statuses
                                                            .filter(s => parseInt(s.type_status) === TYPE_STATUS_PROJECT_PART_ID)
                                                            .map((option, i) => (
                                                                <div
                                                                    key={`my_select_o_${i}`}
                                                                    className={`cursor-pointer badge badge-${option.color}`}
                                                                    option={option}
                                                                >
                                                                    {option.display_name}
                                                                </div>
                                                            ))}
                                                    </CustomSelect>
                                                </td>
                                                <td>
                                                    <CustomSelect
                                                        defaultValue={GetStatusCNCForPart(part.status_cnc)}
                                                        clickable={false}
                                                    ></CustomSelect>
                                                </td>
                                                <td>{part.name_material}</td>
                                                <td>
                                                    <IconWithTooltip
                                                        title="Wymiary"
                                                        content={part.dimensions}
                                                        pathIcon={mdiMoveResizeVariant}
                                                    />
                                                </td>
                                                <td>
                                                    <Attachment
                                                        hyperlink={part.hyperlink}/>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-info btn-rounded btn-icon"
                                                        title={"Cofnij"}
                                                        onClick={() => handleBack(part.id_position_part)}
                                                    >
                                                        <Icon
                                                            path={mdiBackupRestore}
                                                            size={0.7}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ))
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ProjectDetailModalAddPart
            show={showModal}
            setShow={setShowModal}
            statuses={statuses}
            dataModal={dataModal}
            allProject={allProjects}
        />
        <PartModalDetail
            part_id={partId}
            setShow={setPartModalDetailShow}
            show={partModalDetailShow}
            statuses={statuses}/>
    </>
}

export default PartOrdersProject;