export const fetchCurrentUser = async (axiosInstance) => {
    try {
        const response = await axiosInstance.post('/current-user');
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUserList = async (axiosInstance) => {
    try {
        const response = await axiosInstance.get('/users');
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUser = async (axiosInstance, userId) => {
    try {
        const response = await axiosInstance.get(`/users/${userId}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUserCreate = async (axiosInstance, data) => {
    try {
        const response = await axiosInstance.post('/user/create', data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUserUpdate = async (axiosInstance, userId, data) => {
    try {
        const response = await axiosInstance.put(`/user/update/${userId}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUserDelete = async (axiosInstance, userId) => {
    try {
        const response = await axiosInstance.delete(`/user/delete/${userId}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}