import React, {useEffect, useState} from 'react';
import {fetchRoleList} from "../../api/ApiRole";
import useAxios from "../../api/useAxios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {fetchUser, fetchUserCreate, fetchUserUpdate} from "../../api/ApiUser";

const UserForm = ({_type}) => {
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const { id } = useParams();

    const [roles, setRoles] = React.useState([]);
    const [messages, setMessages] = React.useState({message: '', status: ''});

    const [inputs, setInputs] = useState({
        login: '',
        password: '',
        email: '',
        display_name: '',
        id_role: ''
    });

    const handleChangeInput = (name, value) => {
        setInputs((prevState) => ({
            ...prevState, [name]: value,
        }));
    }

    const handleCreateUser = async (e) => {
        e.preventDefault();
        const response = await fetchUserCreate(axiosInstance, inputs);

        if(response.status === 'success') {
            navigate('/users', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        const response = await fetchUserUpdate(axiosInstance, id, inputs);

        if(response.status === 'success') {
            navigate('/users', {state: {message: response.message, status: 'success'}});
        }
        else {
            setMessages({
                message: response.message,
                status: 'danger',
            });
        }
    }

    useEffect(() => {
        if(_type === 'edit') {
            getUserForId(id);
        }

        async function getUserForId(userId) {
            const response = await fetchUser(axiosInstance, userId);

            if(response.status === 'success') {
                setInputs({
                    login: response.user.login,
                    password: '',
                    email: response.user.email,
                    display_name: response.user.display_name,
                    id_role: response.user.id_role,
                });
            }
            else {
                navigate('/users', {state: {message: response.message, status: 'danger'}});
            }
        }
    }, [_type])

    useEffect(() => {
        getRoles();

        async function getRoles() {
            const response = await fetchRoleList(axiosInstance);
            if (response.status === 'success') {
                setRoles(response.roles);

                if(_type === 'create') {
                    handleChangeInput('id_role', response.roles[0].id);
                }
            }
        }
    }, [axiosInstance, _type]);

    if (!roles.length) {
        return "";
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{_type === 'create' ? 'Dodaj użytkownika' : 'Edytuj użytkownika'} </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/users'}>Użytkownicy</Link></li>
                        <li className="breadcrumb-item active"
                            aria-current="page">{_type === 'create' ? 'Dodaj użytkownika' : 'Edytuj użytkownika'}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {messages && <div className={`alert alert-${messages.status} text-small`}>{messages.message}</div>}
                            <form className="forms-sample" onSubmit={_type === 'create' ? handleCreateUser : handleUpdateUser}>
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_login">Login</label>
                                    <input type="text" className="form-control" id="InputDisplay_login"
                                           value={inputs.login} placeholder="Login"
                                           onChange={(e) => handleChangeInput('login', e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_password">Hasło</label>
                                    <input type="password" className="form-control" id="InputDisplay_password"
                                           value={inputs.password} placeholder="Hasło"
                                           onChange={(e) => handleChangeInput('password', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputDisplay_email">Email</label>
                                    <input type="email" className="form-control" id="InputDisplay_email"
                                           value={inputs.email} placeholder="Email"
                                           onChange={(e) => handleChangeInput('email', e.target.value)}
                                    />
                                </div>
                                <div className=" form-group">
                                    <label htmlFor=" InputDisplay_name">Wyświetlana nazwa</label>
                                    <input type=" text" className=" form-control" id=" InputDisplay_name"
                                           value={inputs.display_name} placeholder="Wyświetlana nazwa"
                                           onChange={(e) => handleChangeInput('display_name', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputRole">Rola</label>
                                    <select className="form-control" id="InputRole"
                                            
                                            onChange={(e) => handleChangeInput('id_role', e.target.value)}
                                            defaultValue={inputs.id_role}
                                    >
                                        {roles.map((role, i) => (
                                            <option key={i} value={role.id}>{role.display_name}</option>
                                        ))}
                                    </select>
                                </div>

                                <br/><br/>
                                <button type="submit"
                                        className="btn btn-primary mr-2">{_type === 'create' ? 'Utwórz' : 'Edytuj'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserForm;
