import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useAxios from "../api/useAxios";
import {fetchGetPositionPart} from "../api/ApiProject";
import {getCurrentDateTime} from "../helpers/convertDate";
import {complicatedStatus, TYPE_STATUS_CNC, TYPE_STATUS_PROJECT_PART_ID} from "../helpers/Status";
import Attachment from "./Attachment";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";

const PartModalDetail = ({ part_id, show, setShow, statuses }) => {
    const axiosInstance = useAxios();

    const [part, setPart] = useState([]);
    const [position, setPosition] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        if(show) {
            getPart();
        }

        async function getPart() {
            const response = await fetchGetPositionPart(axiosInstance, part_id);

            if(response.status === 'success') {
                setPart(response.position_part);
                setPosition(response.position);
                setProject(response.project);
            }
        }
    }, [show]);


    const handleStatuses = (status_id) => {
        const status = statuses.find(s => parseInt(s.id) === parseInt(status_id));

        if(!status) {
            return (
                <div
                    className={`badge badge-sm badge-outline-light`}>
                    Brak danych
                </div>
            )
        }

        return (
            <div
                className={`badge badge-sm badge-${status.color}`}>
                {status.display_name}
            </div>
        )
    }

    const handleComplicated = (complicated_id, status_id) => {
        const complicated = complicatedStatus.find(c => c.id === parseInt(complicated_id));

        if(!complicated) {
            return (
                <div
                    className={`badge badge-sm badge-outline-light`}>
                    Brak danych
                </div>
            )
        }

        return (
            <div
                className={`badge badge-sm badge-${complicated.color}`}>
                {complicated.display_name}
            </div>
        )
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Informacje o części</Modal.Title>
                <Icon path={mdiClose}
                      size={1}
                      onClick={() => setShow(false)}
                      className={"cursor-pointer"}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-1">
                    <div className="card-body">
                        <div className="table-card">
                            <table className="table mb-0 part-modal-detail-table">
                                <tbody>
                                <tr>
                                    <td className="fw-medium">Nazwa projektu</td>
                                    <td>{project.display_name}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Numer pozycji</td>
                                    <td>{position.display_number}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Nazwa pozycji</td>
                                    <td>{position.display_name}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Numer część</td>
                                    <td>{part.display_number}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Nazwa części</td>
                                    <td>{part.display_name}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Ilość</td>
                                    <td>{part.quantity}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Wymiary</td>
                                    <td>{part.dimensions}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Nazwa materiału</td>
                                    <td>{part.name_material}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Zamówienie materiału</td>
                                    <td>{handleStatuses(part.material_order)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Hartowanie</td>
                                    <td>
                                        <div
                                            className={`badge badge-sm badge-outline-${part.temper === "0" ? "danger" : "success"}`}
                                        >
                                            {part.temper === "0" ? "NIE" : "TAK"}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Azotowanie</td>
                                    <td>
                                        <div
                                            className={`badge badge-sm badge-outline-${part.azotize === "0" ? "danger" : "success"}`}
                                        >
                                            {part.azotize === "0" ? "NIE" : "TAK"}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Status części</td>
                                    <td>{handleStatuses(part.status)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Status obróbki</td>
                                    <td>{handleStatuses(part.status_cnc)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Stopień skomplikowania</td>
                                    <td>{handleComplicated(part.complicated, part.status)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Priorytet</td>
                                    <td>{handleStatuses(part.priority)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Czas rozpoczęcia</td>
                                    <td>{getCurrentDateTime(part.time_start)}</td>
                                </tr>
                                <tr>
                                    <td className="fw-medium">Czas zakończenia</td>
                                    <td> {getCurrentDateTime(part.time_end)}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="py-2 px-3">
                                <div className="row">
                                    <div className="col-12">
                                        <small style={{color: '#6c7293'}}>
                                            {part.description === "" ? "Brak opisu" : part.description}
                                        </small>
                                    </div>
                                    <div className="col-12">
                                        {part.hyperlink === "" ? "" : <Attachment hyperlink={part.hyperlink}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShow(false)}>Zamknij</button>
            </Modal.Footer>
        </Modal>
    );
}

export default PartModalDetail;