import axios from 'axios';

const AxiosCreate = axios.create({
    baseURL: 'https://api.panelcnc.cfolks.pl/api',
     //baseURL: 'http://localhost:8080/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default AxiosCreate;
