export const fetchGetStatus = async (axiosInstance) => {
    try {
        const response = await axiosInstance.get('/statuses');
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchGetStatusForType = async (axiosInstance, type_status) => {
    try {
        const response = await axiosInstance.get(`/statuses/type/${type_status}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchStatus = async (axiosInstance, idStatus) => {
    try {
        const response = await axiosInstance.get(`/statuses/${idStatus}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchCreateStatus = async (axiosInstance, data) => {
    try {
        const response = await axiosInstance.post(`/status/create`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchDeleteStatus = async (axiosInstance, idStatus) => {
    try {
        const response = await axiosInstance.delete(`/status/delete/${idStatus}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUpdateStatus = async (axiosInstance, idStatus, data) => {
    try {
        const response = await axiosInstance.put(`/status/update/${idStatus}`, data);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const fetchUpdatePosition = async (axiosInstance, newPositions) => {
    try {
        const response = await axiosInstance.put(`/status/update-positions`, {new_positions: JSON.stringify(newPositions)});
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}